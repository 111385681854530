import './ClusterList.scss';

import React, { useContext } from "react";
import { List, AutoSizer } from "react-virtualized";
import IAuthorMeta from "../../interfaces/authorMeta";
import IPaperMeta from "../../interfaces/paperMeta";
import MapContext from "../../contexts/MapContext";
import PaperListItem from "./PaperListItem";
import IClusterMeta from '../../interfaces/clusterMeta';
import ClusterListItem from './ClusterListItem';
import IClusterSearchResultMeta from '../../interfaces/clusterSearchResultMeta';
import ClusterSearchListItem from './ClusterSearchListItem';

export default function ClusterSearchList({ clusters }: { clusters: IClusterSearchResultMeta[] }) {
  const {  } = useContext(MapContext);

  // Row renderer for react-virtualized
  const rowRenderer = ({ key, index, style }: any) => {
    const cluster = clusters[index];

    return (
      <div
        className="clusterList"
        style={{
          ...style,
          height: "120px",
          maxHeight: "110px",
          padding:"10px",
          borderBottom: "1px solid lightgrey",
        }}
        key={index}
      >
        <ClusterSearchListItem cluster={cluster} />
      </div>
    );
  };

  return (
    <div style={{ height: "calc(100vh - 170px)", width: "100%" }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            width={width}
            height={height}
            rowCount={clusters.length}
            rowHeight={140} // Adjust based on your content
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
}
