import { Button, CircularProgress, IconButton } from "@mui/material";
import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
import React, { useContext, useState } from "react";
import "./PaperPictureDisplay.scss"; // Import the CSS file for styles
import { fetchPaperDetailsWithCoordinates } from "../../requests";
import MapContext from "../../contexts/MapContext";
import RefreshIcon from '@mui/icons-material/Refresh';

export default function PaperPictureDisplay({
  paperDetails,
  showText=false
}: {
  paperDetails: IPaperDetailsWithCoordinates;
  showText?: boolean;
}) {
const {mapName, selectedCorpusId, setSelectedPaperDetails}=useContext(MapContext);
const [isGenerating, setIsGenerating] =useState(false);

const generatePicture=()=>{
    setIsGenerating(true);
    fetchPaperDetailsWithCoordinates(mapName, selectedCorpusId!,true).then((paperDetails:IPaperDetailsWithCoordinates)=>{
        setSelectedPaperDetails(paperDetails);
    }).catch(console.error).finally(()=>setIsGenerating(false));
}

  return (
    <div className="paperPicture" style={{ position: "relative" }}>
      <img
        className="paperPictureImage" // Assign a class for targeting in CSS
        src={paperDetails.pictureurl || showText ? "/paper_plain.webp": '/paper4.jpg'}
        alt="picture could not be loaded"
      />
      {showText && <div className="overlay-text">{paperDetails.title}</div>}
      <IconButton
        className="btn-regenerate"
        disabled={isGenerating}
        onClick={generatePicture}
        style={{ position: "absolute", top: 10, right: 10, backgroundColor: "rgba(255, 255, 255, 0.7)" }}
        size="small"
      >
        {isGenerating ? <CircularProgress size={20} /> : <RefreshIcon />}
      </IconButton>
    </div>
  );

}
