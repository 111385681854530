// src/App.js
import React from "react";
import "./App.css";
import { MapProvider } from "./contexts/MapContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MapHandler from "./components/MapHandler"; // Import the new component
import { SnackbarContextProvider } from "./contexts/SnackbarContext";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login"; // Import the Login component
import Impressum from "./pages/Impressum";
import AboutPage from "./pages/AboutPage";

export default function App() {
  return (
    <BrowserRouter>
      <SnackbarContextProvider>
        <MapProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<Navigate to="/map/reembed_4" replace />} />
              <Route path="/login" element={<Login />} />
              <Route
                  path="/impressum"
                  element={<Impressum />}
                />
              <Route 
                  path="/about"
                  element={<AboutPage />}
                />
              {/* Protected Routes */}
              <Route element={<PrivateRoute />}>
                <Route path="/map/:name" element={<MapHandler />} />
                <Route path="/map/:name/search/:query" element={<MapHandler />} />
                <Route
                  path="/map/:name/search/:query/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/corpus/:corpusId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/corpus/:corpusId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/author/:authorId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/author/:authorId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/corpus/:corpusId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/search/:query/corpus/:corpusId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId/corpus/:corpusId"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/author/:authorId/corpus/:corpusId/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                <Route
                  path="/map/:name/coordinates/:coordinates"
                  element={<MapHandler />}
                />
                {/* Add more protected routes here */}
              </Route>
            </Routes>
          </div>
        </MapProvider>
      </SnackbarContextProvider>
    </BrowserRouter>
  );
}