import React, { useState, useEffect, useRef } from 'react';
import './CollapsibleSegment.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function CollapsibleSegment({
  name,
  children,
  collapsible = true,
  collapsed = false,
  maxPreviewHeight = 200,
}: {
  name: string;
  children: React.ReactNode;
  collapsible?: boolean;
  collapsed?: boolean;
  maxPreviewHeight?: number | null;
}) {
  const [isCollapsed, setCollapsed] = useState(collapsed);
  const [isPreview, setPreview] = useState(maxPreviewHeight !== null);
  const [showOverlay, setShowOverlay] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setCollapsed(!isCollapsed);
    setPreview(maxPreviewHeight !== null);
  };

  const handleShowMore = () => {
    setPreview(false);
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (!contentElement || maxPreviewHeight === null) return;

    const resizeObserver = new ResizeObserver(() => {
      if (contentElement.scrollHeight > maxPreviewHeight) {
        setShowOverlay(true);
      } else {
        setShowOverlay(false);
      }
    });

    resizeObserver.observe(contentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [maxPreviewHeight]);

  return (
    <div className="collapsibleSegment">
      <div className="segmentHeader" onClick={collapsible ? handleToggle : undefined}>
        {name}
        {collapsible && (
          <div className="segmentHeaderIcon">
            {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
        )}
      </div>
      <div
        className={`segmentContent ${isPreview ? 'preview' : ''}`}
        style={{ display: isCollapsed ? 'none' : 'block', maxHeight: isPreview ? `${maxPreviewHeight}px` : 'none' }}
        ref={contentRef}
      >
        {children}
        {isPreview && showOverlay && (
          <div className="gradientOverlay">
            <button className="showMoreButton" onClick={handleShowMore}>More</button>
          </div>
        )}
      </div>
    </div>
  );
}
