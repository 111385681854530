import { Grid, Tab, Tabs } from "@mui/material";
import { useContext, useState } from "react";
import MapContext from "../contexts/MapContext";
import ClusterSearchList from "./lists/ClusterSearchList";
import PaperList from "./lists/PaperList";

export default function QuickActionPanel({}) {
  const {
    mapName,
    viewport,
    zoom,
    selectedPaperDetails,
    searchPaperResults,
    setSearchPaperResults,
    searchClusterResults,
  } = useContext(MapContext);

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getPaperCountLabel = () => {
    if (searchPaperResults && searchPaperResults.length > 0) {
      return ` (${searchPaperResults.length})`;
    }
    return "";
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ backgroundColor: "white", height: "100%" }}
    >
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          {/*<Tab label="Papers" />*/}
          {/*<Tab label="Clusters"/>*/}
          <Tab label={`Papers${getPaperCountLabel()}`} />
          <Tab label="Topics" />
          {/*<Tab label="Viewports" />
          <Tab label="GPT" />
          <Tab label="Tab 3" />
          <Tab label="Tab 4" />
          <Tab label="Tab 5" />
  <Tab label="Tab 6" />*/}
        </Tabs>
      </Grid>
      {/*currentTab === 0 && (
        <Grid item xs={12}>
          <PapersPanel />
        </Grid>
      )*/}
      {/*currentTab === 1 && (
        <Grid item xs={12}>
          <ClustersPanel />
        </Grid>
      )*/}

      {currentTab === 0 && (
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          {searchPaperResults && (
            <div style={{ width: "100%", height: "100%" }}>
              <PaperList papers={searchPaperResults} type={"search"} />
            </div>
          )}
        </Grid>
      )}
      {currentTab == 1 && (
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          {searchClusterResults && (
            <div style={{ width: "100%", height: "100%" }}>
              <ClusterSearchList clusters={searchClusterResults} />
            </div>
          )}
        </Grid>
      )}
      {/*currentTab === 4 && (
        <Grid item xs={12}>
          <BookmarkPanel />
        </Grid>
      )*/}
      {/*currentTab === 5 && (
        <Grid item xs={12}>
          <GPTPanel />
        </Grid>
      )*/}
    </Grid>
  );
}
