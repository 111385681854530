import { Button, CircularProgress, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { fetchClusterDetails, generateClusterDescription } from "../../requests";
import MapContext from "../../contexts/MapContext";
import IClusterDetails from "../../interfaces/clusterDetails";
import PaperList from "../lists/PaperList";
import ClusterPictureDisplay from "../paper/ClusterPictureDisplay";
import WordFrequencyBarChart from "../paper/WordFrequencyBarChart";
import "./ClusterDetailsPanel.scss";

import { Tab, Tabs } from "@mui/material";
import { useSnackbar } from "../../contexts/SnackbarContext";
import IPaperMeta from "../../interfaces/paperMeta";
import ClusterPreviewBatch from "../clusters/ClusterPreviewBatch";
import ClusterPreviewBatchList from "../clusters/ClusterPreviewBatchList";
import PaperListItem from "../lists/PaperListItem";
import CollapsibleSegment from "./CollapsibleSegment";
import LazyGenerateSection from "./LazyGenerateSection";
import ClusterAIButton from "../paper/ClusterAIButton";
import cluster from "cluster";
import { map } from "lodash";
import CopySelectionButton from "../utils/CopySelectionButton";
import CodeIcon from "@mui/icons-material/Code";
import AIQuestionButton from "../ai/AskAIButton";
import { useLocation } from "react-router-dom";
export default function ClusterDetailsPanel() {
  const [selectedTab, setSelectedTab] = useState(0);
  const snackbar = useSnackbar();
  const {
    selectedCluster,
    selectedClusterDetails,
    setSelectedClusterDetails,
    mapName,
    setSelectedCluster,
    setPromptingSelection,
  } = useContext(MapContext);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const isDebugMode = location.search.includes('debug');
  useEffect(() => {
    if (selectedCluster) {
      setIsLoading(true);
      fetchClusterDetails(mapName, selectedCluster.cluster_id)
        .then((details: IClusterDetails) => {
          setSelectedClusterDetails(details);
          //setSelectedCluster(details); //e.g. to upate the geometry which is not available when clicking on the label
        })
        .catch((e) => {
          setSelectedClusterDetails(null);
          setSelectedCluster(null);
          snackbar.showSnackbar("Error fetching cluster details", "error");
        })
        .finally(() => setIsLoading(false));
    } else {
      setSelectedClusterDetails(null);
    }

    {
      setSelectedClusterDetails(null);
    }
  }, [selectedCluster?.cluster_id]);

  return (
    <div className="clusterPanel">
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Details" />
        <Tab label="Papers" />
        {isDebugMode && <Tab label="Words" />}
        {false && <Tab label="Pictures" />}
      </Tabs>
      <div>
      </div>
      <div className="clusterContainer">
        {!isLoading && selectedClusterDetails && (
          <>
            {selectedTab === 0 && (
              <>
<div style={{marginLeft:'-20px',marginRight:'-20px',marginTop:'-20px',marginBottom:'5px'}}><ClusterPictureDisplay clusterDetails={selectedClusterDetails} /></div>       

                <div className="clusterHeader" style={{ textAlign: "left" }}>
                  {selectedClusterDetails.label.toUpperCase()}
                  {isDebugMode && !selectedClusterDetails.image_url && (
                    <ClusterAIButton
                      clusterId={selectedClusterDetails.cluster_id}
                      mapName={mapName}
                    />
                  )}
                  {isDebugMode && (
                    <IconButton
                      onClick={() => {
                        setPromptingSelection({
                          selectionType: "cluster",
                          selectionArgs: {
                            map_name: mapName,
                            cluster_id: selectedClusterDetails.cluster_id,
                          },
                        });
                      }}
                    >
                      <CodeIcon />
                    </IconButton>
                  )}
                  {isDebugMode && (
                    <CopySelectionButton
                      selectionConfig={{
                        selection_type: "cluster",
                        map_name: mapName,
                        cluster_id: selectedClusterDetails.cluster_id,
                      }}
                    />
                  )}
                </div>
                <div className="cluster-segment">
                  {selectedClusterDetails.short_description && (
                    <CollapsibleSegment name={"Short Description"}>
                      {selectedClusterDetails.short_description}
                    </CollapsibleSegment>
                  )}
                </div>
                {false && (
                  <>
                    <LazyGenerateSection
                      label="Main Research Questions"
                      description={""}
                    />
                    <LazyGenerateSection
                      label="Main Contributors"
                      description={""}
                    />
                    <LazyGenerateSection
                      label="Main Methods"
                      description={""}
                    />
                    <LazyGenerateSection
                      label="Main Findings"
                      description={""}
                    />
                    <LazyGenerateSection
                      label="Main Limitations"
                      description={""}
                    />
                    <LazyGenerateSection
                      label="Main Implications"
                      description={""}
                    />
                    <LazyGenerateSection
                      label="Main Applications"
                      description={""}
                    />
                  </>
                )}

                <CollapsibleSegment name="Keywords">
                <AIQuestionButton
                    autoGenerate={true}
                    question={"What are the main keywords of this field?"}
                    prompt={
                      "Give a list of max 10 keywords which are mentioned in the papers of this cluster. Return as a bulletpoint list  including an indicator of how strong this keyword is in this cluster."
                    }
                    contexts={{
                      method: "sampled_titles",
                      sampling: {
                        n: 100,
                      },
                    }}
                    selection={{
                      selection_type: "cluster",
                      map_name: mapName,
                      cluster_id: selectedClusterDetails.cluster_id,
                    }}
                  />
                </CollapsibleSegment>
                <CollapsibleSegment
                  name="Research Question"
                  maxPreviewHeight={null}
                >
                  <AIQuestionButton
                    autoGenerate={false}
                    question={"What is this field of research about?"}
                    prompt={
                      "Describe the main research question behind this cluster (represented by this selection of paper titles) in one short simple sentence that can be understood by a layperson. Start with 'How ...?' or 'What is ...?' or similar."
                    }
                    contexts={{
                      method: "sampled_titles",
                      sampling: {
                        n: 50,
                      },
                    }}
                    selection={{
                      selection_type: "cluster",
                      map_name: mapName,
                      cluster_id: selectedClusterDetails.cluster_id,
                    }}
                  />
                </CollapsibleSegment>
                <CollapsibleSegment name="Description">
                  <AIQuestionButton
                    question={"What is this field of research about?"}
                    prompt={
                      "Describe this cluster (represented by this selection of paper titles) in up to 4 simple bulletpoints with one sentence each. Refer to this cluster as 'this research field' or similar. Use simple language that can be understood by a layperson."
                    }
                    contexts={{
                      method: "sampled_titles",
                      sampling: {
                        n: 50,
                      },
                    }}
                    selection={{
                      selection_type: "cluster",
                      map_name: mapName,
                      cluster_id: selectedClusterDetails.cluster_id,
                    }}
                  />
                </CollapsibleSegment>
                <CollapsibleSegment name="Applications">
                  <AIQuestionButton
                    question="What are applications for this field of research?"
                    prompt={
                      "How does this field of research (represented by the given selection of paper titles) apply to real-world problems? Return a short list of up to 4 elements. Use simple language that can be understood by a layperson."
                    }
                    contexts={{
                      method: "sampled_titles",
                      sampling: {
                        n: 50,
                      },
                    }}
                    selection={{
                      selection_type: "cluster",
                      map_name: mapName,
                      cluster_id: selectedClusterDetails.cluster_id,
                    }}
                  />
                </CollapsibleSegment>
                {selectedClusterDetails.child_clusters.length > 0 && (
                  <>
                    <CollapsibleSegment name="Child Topics">
                      <div>
                        <ClusterPreviewBatchList
                          relation="child"
                          clusterMetas={selectedClusterDetails.child_clusters}
                        />
                      </div>
                    </CollapsibleSegment>
                  </>
                )}
                {selectedClusterDetails.parent_cluster && (
                  <CollapsibleSegment name="Parent Topic" maxPreviewHeight={null}>
                    <ClusterPreviewBatch
                      relation="parent"
                      clusterMeta={selectedClusterDetails.parent_cluster}
                    />
                  </CollapsibleSegment>
                )}
                <CollapsibleSegment
                  name="Most Cited Papers"
                  maxPreviewHeight={null}
                >
                  <div style={{ fontSize: "13px", textAlign: "left" }}>
                    {selectedClusterDetails.top_papers
                      .slice(0, 4)
                      .map((paper: IPaperMeta) => (
                        <PaperListItem key={paper.id} paper={paper} type={"cluster"} />
                      ))}
                  </div>
                </CollapsibleSegment>
                <CollapsibleSegment name="Top Words" collapsed={true}>
                  <div
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                    }}
                  >
                    {selectedClusterDetails.contexts_by_type?.tf && selectedClusterDetails.contexts_by_type.tf
                      .slice(0, 10)
                      .map((tuple: [string, number]) => (
                        <span
                          key={tuple[0]}
                          style={{
                            backgroundColor: "#f0f0f0",
                            borderRadius: "4px",
                            padding: "4px 8px",
                            display: "inline-block",
                          }}
                          className="clusterTopWord"
                        >
                          {tuple[0]} ({tuple[1]})
                        </span>
                      ))}
                  </div>
                </CollapsibleSegment>
              </>
            )}
             {selectedTab === 1 && (
              <PaperList papers={selectedClusterDetails.top_papers} type={"cluster"}/>
            )}
            {selectedTab === 2 && (
             selectedClusterDetails.contexts_by_type?.tf && <WordFrequencyBarChart
                frequencies={selectedClusterDetails.contexts_by_type.tf}
              />
            )}
           
          </>
        )}

        {!selectedClusterDetails && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={20} />
          </div>
        )}
      </div>

      <div
        style={{
          padding: "10px",
          borderTop: "1px solid #eee",
          backgroundColor: "#f9f9f9",
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            setSelectedCluster(null);
            setSelectedClusterDetails(null);
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
