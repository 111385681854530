import { Feature, LngLatBounds } from 'maplibre-gl';

export function getBoundsForFeature(feature: any): LngLatBounds {
  if (!feature || !feature.geometry) {
    throw new Error('Invalid feature geometry.');
  }

  const geometry = feature.geometry;

  // Initialize bounds. Assume the geometry is not empty.
  let bounds = new LngLatBounds();

  // Handle different geometry types
  switch (geometry.type) {
    case 'Point':
      bounds.extend(geometry.coordinates);
      break;
    case 'LineString':
    case 'MultiPoint':
      geometry.coordinates.forEach((coord: [number, number]) => {
        bounds.extend(coord);
      });
      break;
    case 'Polygon':
    case 'MultiLineString':
      geometry.coordinates.forEach((ring: [number, number][]) => {
        ring.forEach((coord: [number, number]) => {
          bounds.extend(coord);
        });
      });
      break;
    case 'MultiPolygon':
      geometry.coordinates.forEach((polygon: [[number, number]][]) => {
        polygon.forEach((ring: [number, number][]) => {
          ring.forEach((coord: [number, number]) => {
            bounds.extend(coord);
          });
        });
      });
      break;
    default:
      throw new Error('Unsupported geometry type.');
  }

  // Optionally expand the bounds slightly to ensure the feature is not too tightly fit
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  // Use small offsets (e.g., 0.01 degrees) to expand the bounds
  bounds.extend([ne.lng + 1e-9, ne.lat + 1e-9]);
  bounds.extend([sw.lng - 1e-9, sw.lat - 1e-9]);

  return bounds;
}
/**
 * Computes the bounding box for a GeoJSON Polygon.
 * @param {number[][][]} coordinates - The coordinates of the Polygon.
 * @returns {number[]} An array of four numbers [minLng, minLat, maxLng, maxLat].
 */
export function computeBoundingBox(coordinates: number[][][]) {
    let minLng = Infinity;
    let minLat = Infinity;
    let maxLng = -Infinity;
    let maxLat = -Infinity;

    // Assuming the first array in coordinates is the outer boundary
    const outerBoundary = coordinates[0]; // Typically, GeoJSON Polygons use the first ring as the outer boundary

    for (let i = 0; i < outerBoundary.length; i++) {
        const point = outerBoundary[i];
        const lng = point[0];
        const lat = point[1];

        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
    }

    return [minLng, minLat, maxLng, maxLat];
}

