import { Button, CircularProgress, Divider, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import {
  fetchPaperCitations,
  fetchPaperDetailsWithCoordinates,
  fetchPaperNearestNeighbors,
  fetchPaperReferences,
} from "../../requests";
import { zip } from "lodash";
import MapContext, {
  HoveredPaperType,
  IHighlightLayer,
} from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import ILandmarkedPapers from "../../interfaces/landmarkedPapers";
import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
import PaperList from "../lists/PaperList";
import AuthorsDisplay from "../paper/AuthorsDisplay";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import PaperAIButton from "../paper/PaperAIButton";
import PaperPictureDisplay from "../paper/PaperPictureDisplay";
import YearDisplay from "../paper/YearDisplay";
import CopySelectionButton from "../utils/CopySelectionButton";
import CodeIcon from "@mui/icons-material/Code";
import CollapsibleSegment from "./CollapsibleSegment";
import IPaperMeta from "../../interfaces/paperMeta";
import { useLocation } from "react-router-dom";

export default function PaperDetailsPanel() {
  const {
    selectedCorpusId,
    setSelectedAuthorDetails,
    setSelectedAuthorId,
    selectedPaperDetails,
    setSelectedPaperDetails,
    setSelectedCorpusId,
    removePaperLayers,
    setPromptingSelection,
    upsertLayer,
    removeLayer,
    mapName,
  } = useContext(MapContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPapers, setIsLoadingPapers] = useState(false);
  useState<ILandmarkedPapers | null>(null);
  const [papers, setPapers] = useState<IPaperMeta[]>([]);
  useState<ILandmarkedPapers | null>(null);
  const [nnLandmarks, setNNLandmarks] = useState<ILandmarkedPapers | null>(
    null
  );
  const isMounted = useRef(true);
  const [currentTab, setCurrentTab] = useState(0);
  const snackbar = useSnackbar();
  const location = useLocation();
  const isDebugMode = location.search.includes('debug');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  // Cleanup effect to set the ref to false when the component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Reset the ref when the component re-mounts
  useEffect(() => {
    isMounted.current = true;
  }, [selectedCorpusId]);

  useEffect(() => {
    if (selectedCorpusId) {
      setSelectedAuthorId(null);
      setSelectedAuthorDetails(null);

      setCurrentTab(0);
      setNNLandmarks(null);
      setIsLoading(true);
      fetchPaperDetailsWithCoordinates(mapName, selectedCorpusId)
        .then((details: IPaperDetailsWithCoordinates) => {
          if (!isMounted.current) return;
          setSelectedPaperDetails(details);
        })
        .catch((error: any) => {
          if (isMounted.current) {
            snackbar.showSnackbar("Error fetching paper details", "error");
            setSelectedCorpusId(null); //hide the panel
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setSelectedPaperDetails(null);
    }
  }, [selectedCorpusId]);

  // Your existing useEffect for handling the tabs and API calls
  useEffect(() => {
    if (!selectedCorpusId) {
      return;
    }

    // Keep track of the current tab to ensure you're not updating when it's changed
    const activeTab = currentTab;

    if (activeTab === 0) {
      setIsLoadingPapers(true);
      setPapers([]);

      fetchPaperCitations(mapName, selectedCorpusId)
        .then((response) => {
          if (!isMounted.current || activeTab !== currentTab) return; // Check if component is unmounted or tab is switched

          removePaperLayers();
          setPapers(response.papers);

          const layers = response.layers;
          const sources = response.sources;
          const zipped = zip(layers, sources);
          for (const [layer, source] of zipped) {
            upsertLayer(layer!.id, layer!, source, "paper_labels");
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            snackbar.showSnackbar("Error fetching author references", "error");
          }
        })
        .finally(() => {
          if (isMounted.current) setIsLoadingPapers(false);
        });
    } else if (activeTab === 1) {
      setIsLoadingPapers(true);
      setPapers([]);

      fetchPaperReferences(mapName, selectedCorpusId)
        .then((response) => {
          if (!isMounted.current || activeTab !== currentTab) return; // Check if component is unmounted or tab is switched

          removePaperLayers();
          setPapers(response.papers);

          const layers = response.layers;
          const sources = response.sources;
          const zipped = zip(layers, sources);
          for (const [layer, source] of zipped) {
            upsertLayer(layer!.id, layer!, source, "paper_labels");
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            console.log(error);
            snackbar.showSnackbar("Error fetching author references", "error");
          }
        })
        .finally(() => {
          if (isMounted.current) setIsLoadingPapers(false);
        });
    } else if (activeTab === 2) {
      fetchPaperNearestNeighbors(mapName, selectedCorpusId!)
        .then((response) => {
          if (!isMounted.current || activeTab !== currentTab) return;
          removePaperLayers();
          setPapers(response.papers);

          const layers = response.layers;
          const sources = response.sources;
          const zipped = zip(layers, sources);
          for (const [layer, source] of zipped) {
            upsertLayer(layer!.id, layer!, source, "paper_labels");
          }
        })
        .catch((error: any) => {
          if (isMounted.current) {
            snackbar.showSnackbar("Error fetching nearest neighbors", "error");
            setCurrentTab(0);
          }
        });
    }
  }, [currentTab, selectedPaperDetails]);



  const getCitationCount = () => {
    if(currentTab==0 && papers && papers.length){
      return `(${papers.length})`;
    }
    return "";
  };

  const getReferenceCount = () => {
    if(currentTab==1 && papers && papers.length){
      return `(${papers.length})`;
    }
    return "";
  }


  const getHoverType = (): HoveredPaperType => {
    if (currentTab == 0) {
      return "paper_citation";
    } else if (currentTab == 1) {
      return "paper_reference";
    }else if (currentTab == 2){
      return "paper_similar";
    }else{
      throw new Error("Invalid tab");
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        zIndex: 4,
        boxShadow: "1px 1px 4px #c5c5c5",
        backgroundColor: "white",
      }}
    >
      {selectedPaperDetails && (
        <>
          <div style={{ flex: 1, overflowY: "auto" }}>
            <PaperPictureDisplay paperDetails={selectedPaperDetails} />

            <div style={{ padding: "20px", paddingTop: 0 }}>
              <h3 style={{ textAlign: "left",marginBottom:'-11px' }}>
                {selectedPaperDetails.title}{" "}
                {isDebugMode && <PaperAIButton
                  selectedCorpusId={selectedPaperDetails.id}
                  mapName={mapName}
                />}
                {isDebugMode && <Button
                  onClick={() => {
                    setPromptingSelection({
                      selectionType: "custom",
                      selectionArgs: {
                        map_name: mapName,
                        ids: [selectedPaperDetails.id],
                      },
                    });
                  }}
                >
                  <CodeIcon />
                </Button>
                }
                {isDebugMode && <CopySelectionButton
                  selectionConfig={{
                    selection_type: "custom",
                    map_name: mapName,
                    ids: [selectedPaperDetails.id],
                  }}
                />}
              </h3>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              
                  <IconButton 
                  sx={{paddingLeft:0}}
                    href={`https://www.semanticscholar.org/paper/${selectedPaperDetails.paperid}`} 
                    target="_blank" 
                    rel="noreferrer" 
                    size="small" 
                  >
                    <Tooltip title="Open in Semanticscholar" arrow>
                      <LinkIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                {selectedPaperDetails.openaccesspdf?.url && (
                  <IconButton
                    href={selectedPaperDetails.openaccesspdf.url}
                    target="_blank"
                    rel="noreferrer"
                    size="small"
                  >
                    <Tooltip title="Open as PDF" arrow>
                      <PictureAsPdfIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                )}
                
              </div>
              <div
                className="meta"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <YearDisplay year={selectedPaperDetails.year} />
                <AuthorsDisplay authors={selectedPaperDetails.authors} />
                <CitationCountDisplay
                  citationCount={selectedPaperDetails.citationcount}
                />
              </div>
              <Divider />
              <div style={{ marginTop: "10px" }}>
                <CollapsibleSegment name={"Abstract"} maxPreviewHeight={200}>
                  <p style={{ margin: 0, fontSize: "14px", textAlign: "left" }}>
                    {selectedPaperDetails.abstract}
                  </p>
                </CollapsibleSegment>
              </div>

              <Divider />
              <Tabs value={currentTab} onChange={handleTabChange}>
                <Tab label={"Citations"+getCitationCount()} />
                <Tab label={"References"+getReferenceCount()} />
                <Tab label="Similar" />
              </Tabs>

              <Divider />
              {isLoadingPapers && (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={20} />
                </div>
              )}
              {!isLoadingPapers && papers.length > 0 && (
                <PaperList papers={papers} type={getHoverType()} />
              )}
              {!isLoadingPapers && !papers?.length && <i>No Papers Found</i>}
            </div>
          </div>
        </>
      )}
      {!selectedPaperDetails && (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
      <div style={{ padding: "10px", borderTop: "1px solid #eee" }}>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            setSelectedCorpusId(null);
            setSelectedPaperDetails(null);
            removePaperLayers();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
