import "./HoverPopup.scss";
import { useContext } from "react";
import MapContext, { IHoveredCluster } from "../../contexts/MapContext";
import AIAnswer from "../ai/AIAnswer";

export default function ClusterHoverPopup({
  hoveredCluster,
  generateDescription=false

}: {
  hoveredCluster: IHoveredCluster;
  generateDescription?: boolean;
}) {
  const { mapName } = useContext(MapContext);

  return (
    <div className="popupHoverCluster" onClick={() => {}}>
      <div className="clusterHeader">Topic</div>
      <div className="clusterLabel">
        {hoveredCluster.label ?? "Not loaded yet"}
      </div>
      <div className="clusterDescription">
        {generateDescription &&
        <AIAnswer
        numSkeletonRows={1}
        allowRegenerate={false}
          autoGenerate={true}
          prompt={
            "Describe the main research question behind this cluster (represented by this selection of paper titles) in one short simple sentence that can be understood by a layperson. Start with 'How ...?' or 'What is ...?' or similar."
          }
          contexts={{
            method: "sampled_titles",
            sampling: {
              n: 50,
            },
          }}
          selection={{
            selection_type: "cluster",
            map_name: mapName,
            cluster_id: hoveredCluster.cluster_id,
          }}
           markdownStyles={{ paragraphFontSize: "10px",paragraphColor: "#333333" }}
        />}
      </div>

      <div className="hoverInfo">Click to view details</div>
    </div>
  );
}
