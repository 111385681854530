import './PaperList.scss';
import { useContext } from "react";
import IPaperMeta from "../../interfaces/paperMeta";
import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import IAuthorMeta from "../../interfaces/authorMeta";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import IClusterMeta from "../../interfaces/clusterMeta";
import ClusterPreviewBatch from "../clusters/ClusterPreviewBatch";
import ClusterPreviewBatchList from "../clusters/ClusterPreviewBatchList";

export default function PaperListItem({ paper,type }: { paper: IPaperMeta,type:HoveredPaperType }) {
  const { setHoveredPaper, setSelectedCorpusId } = useContext(MapContext);
  const topAuthors = (paper.authors ?? [])
    .slice(0, 3)
    .map((author: IAuthorMeta) => author.name)
    .join(", ");
  const moreAuthors = "..."; //paper.authors.length > 3 ? `... ${paper.authors.length - 3} more` : '';

  const onClick = () => {
    setSelectedCorpusId(paper.id);
  };

  return (
    <div
    className="paperListItem"
      onMouseOver={() => {
        setHoveredPaper({
          source: "list",
          geometry: paper.geometry,
          title: paper.title,
          id: paper.id,
          type: type
        });
      }}
      onMouseLeave={() => {
        setHoveredPaper(null);
      }}
      onClick={onClick}
      style={{
        height: "80px",
        maxHeight: "80px",
        padding: "10px",
        borderBottom: "1px solid #ccc",
      }}
    >
      <div
        style={{
          fontWeight: "500",
          textAlign: "left",
          fontSize: "14px",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2, // Limits to 2 lines
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {paper.title}
      </div>
      <div
        style={{
          fontSize: "12px",
          textAlign: "left",
          color: "#555",
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        Year: {paper.year} | <CitationCountDisplay citationCount={paper.citationcount} /> | Authors:{" "}
        {topAuthors} {moreAuthors}
      </div>
      {false && <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: "5px",
          marginTop: "5px",
        }}
      >
        <ClusterPreviewBatchList clusterMetas={paper.cluster_metas ?? []} />
      </div>}
    </div>
  );
}
