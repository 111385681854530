// src/api.js
import axios from "axios";

// Create an Axios instance
const api = axios.create({
  baseURL: "http://localhost:5000", // Replace with your backend URL
});

// Request Interceptor: Attach JWT to every request if available
api.interceptors.request.use(
  (config) => {
    const tokens = localStorage.getItem("tokens")
      ? JSON.parse(localStorage.getItem("tokens") || "{}")
      : null;
    if (tokens && tokens.access_token) {
      config.headers["Authorization"] = `Bearer ${tokens.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor: Handle 401 Unauthorized globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Remove tokens from storage
      localStorage.removeItem("tokens");

      // Get the current path the user is trying to access
      const currentPath = window.location.pathname + window.location.search;

      // Redirect to login with the current path as a query parameter
      window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`;
    }
    return Promise.reject(error);
  }
);

export default api;