import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

const WordFrequencyBarChart = ({ frequencies }:{frequencies:[string,number][]}) => {
    // Determine the maximum frequency for scaling the bars
    const maxFrequency = Math.max(...frequencies.map(([_, freq]) => freq));

    return (
        <Box>
            {frequencies.map(([word, frequency], index) => (
                <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">{word} ({frequency})</Typography>
                    <LinearProgress variant="determinate" value={(frequency / maxFrequency) * 100} />
                </Box>
            ))}
        </Box>
    );
};

export default WordFrequencyBarChart;
