import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Impressum = () => {
  // Simple email obfuscation by splitting into parts
  const emailName = 'emanuel.gerber';
  const emailDomain = 'scholarmaps.com';

  return (
    <Container maxWidth="md">
      <Box sx={{ padding: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Impressum
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Angaben gemäß § 5 TMG
        </Typography>
        
        <Typography variant="body1" paragraph>
          Emanuel Johannes Gerber<br />
          Bert-Brecht-Str. 61<br />
          66386 St. Ingbert
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Kontakt
        </Typography>
        
        <Typography variant="body1">
          E-Mail: {emailName}[at]{emailDomain}
        </Typography>

        <Typography variant="body2" sx={{ mt: 4, color: 'text.secondary' }}>
          Bei geschäftlichen Anfragen bitte bevorzugt per E-Mail kontaktieren.
        </Typography>
      </Box>
    </Container>
  );
};

export default Impressum;
