import { useContext } from "react";
import IPaperMeta from "../../interfaces/paperMeta";
import MapContext from "../../contexts/MapContext";
import IAuthorMeta from "../../interfaces/authorMeta";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import IClusterMeta from "../../interfaces/clusterMeta";
import { LngLatBounds } from "react-map-gl";
import { computeBoundingBox } from "../../geometryUtils";
import IClusterSearchResultMeta from "../../interfaces/clusterSearchResultMeta";

export default function ClusterSearchListItem({
  cluster,
}: {
  cluster: IClusterSearchResultMeta;
}) {
  const { setHoveredCluster, setSelectedCluster } = useContext(MapContext);

  const onClick = () => {
    let bounds = null;
    if (cluster.bounding_box) {
      bounds = computeBoundingBox(cluster.bounding_box.coordinates);
    }
    setSelectedCluster({
      cluster_id: cluster.cluster_id,
      bounds: bounds,
      geometry: cluster.geometry,
    });
  };

  return (
    <div
    className="clusterListItem"
      onMouseOver={() => {
        setHoveredCluster({
          source: "list",
          geometry: cluster.geometry,
          cluster_id: cluster.cluster_id,
        });
      }}
      onMouseLeave={() => {
        setHoveredCluster(null);
      }}
      onClick={onClick}
      style={{
        height: "110px",
        maxHeight: "110px",
        padding: "5px",
        borderBottom: "1px solid #ccc",
      }}
    >
      <div
        style={{
          fontWeight: "500",
          textAlign: "left",
          fontSize: "14px",
          overflow: "ellipsis",
        }}
      >
        {cluster.label.toUpperCase()}
      </div>
      <div
        style={{
          fontSize: "12px",
          textAlign: "left",
          color: "#555",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <b>{cluster.count_search_matches} papers found</b>
        <div>
          {cluster.top_search_results.slice(0, 3).map((paper: IPaperMeta) => {
            return (
              <div
                key={paper.id}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%", // Adjust maxWidth to fit the container's width
                }}
              >
                - {paper.title}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
