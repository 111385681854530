// src/components/ColorLegend.tsx

import React from "react";
import { Tooltip, Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface Interval {
  label: string;
  color: string;
}

const intervals: Interval[] = [
  { label: "Before 1970", color: "#f8f4eb" },
  { label: "1970 - 1980", color: "#f2ebdb" },
  { label: "1980 - 1990", color: "#f1e1ca" },
  { label: "1990 - 2000", color: "#ecdcc0" },
  { label: "2000 - 2010", color: "#a8dab5" },
  { label: "2010 - 2015", color: "#94d2a4" },
  { label: "2015 - 2020", color: "#87c399" },
  { label: "After 2020", color: "#6cb5a9" },
];

const ColorLegend: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "4px 8px",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
        gap: "4px",
      }}
    >
      <Typography variant="caption" sx={{ color: "#666" }}>
        Earlier
      </Typography>
      
      {intervals.map((interval, index) => (
        <Tooltip key={index} title={interval.label} arrow placement="top">
          <Box
            sx={{
              width: 15,
              height: 15,
              backgroundColor: interval.color,
              border: "1px solid #ccc",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      ))}

      <Typography variant="caption" sx={{ color: "#666" }}>
        Recent
      </Typography>

      <Tooltip title="Colors represent the average publication year of research papers in each region" arrow>
        <InfoIcon sx={{ fontSize: 16, marginLeft: 0.5, color: "#666", cursor: "help" }} />
      </Tooltip>
    </Box>
  );
};

export default ColorLegend;
