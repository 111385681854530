import { Button } from "@mui/material";
import _ from "lodash";
import { StyleSpecification } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";
import type { MapRef } from "react-map-gl/maplibre";
import Map, { Popup } from "react-map-gl/maplibre";
import { GOOGLE_MAPS_COLORS } from "../colors";
import MapContext, { IHighlightLayer } from "../contexts/MapContext";
import {
  createLandmarkLayerConfig,
  createLandmarkLayerProps,
} from "../createLandmarkLayer";
import {
  createPolygonConfig,
  createPolygonLayerProps,
} from "../createPolygonLayer";
import { getBoundsForFeature } from "../geometryUtils";
import { ILayer, IMapConfig, ISourceJSON } from "../interfaces/mapConfig";
import overrideLayerStyles from "../map_styling/overrideLayerStyles";
import "./MapStyles.scss";
import ClusterHoverPopup from "./popup/ClusterHoverPopup";
import PaperHoverPopup from "./popup/PaperHoverPopup";
import { StyledSearchIcon } from "./SearchButton";
import CopySelectionButton from "./utils/CopySelectionButton";
import { fetchPaperDetails } from "../requests";
import { useSearch } from "../hooks/useSearch";
import AreaSearch from "./AreaSearch";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useLocation } from "react-router-dom";

interface IOrder {
  beforeId?: string;
  afterId?: string;
}
interface ISourceInformation {
  layerProps: LayerProps;
  order?: IOrder;
  isCustom: boolean;
  data?: any;
  type?: string;
  id: string;
}

const MIN_ZOOM_FOR_AREA_SEARCH=6;

type CombinedLayerType =
  | { id: string; type: "layer"; data: LayerProps }
  | { id: string; type: "highlight"; data: LayerProps };

const DEFAULT_CONTAINER_STYLE = {
  backgroundImage: `
  linear-gradient(45deg, ${GOOGLE_MAPS_COLORS.loadingBackground} 25%, transparent 25%),
  linear-gradient(-45deg, ${GOOGLE_MAPS_COLORS.loadingBackground} 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, ${GOOGLE_MAPS_COLORS.loadingBackground} 75%),
  linear-gradient(-45deg, transparent 75%, ${GOOGLE_MAPS_COLORS.loadingBackground}75%)`,
  backgroundSize: "20px 20px",
  backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
  width: "100vw",
  height: "100vh",
  cursor: "default",
};

const MapReactMap = ({
  config,
  sourceJSON,
}: {
  config: IMapConfig;
  sourceJSON: ISourceJSON;
}) => {
  const {
    darkmode,
    viewport,
    setViewport,
    zoom,
    targetCenter,
    setZoom,
    selectedCorpusId,
    searchPaperResults,
    setSelectedCorpusId,
    sidebarOpen,
    setSelectedCluster,
    selectedAuthorId,
    selectedAuthorDetails,
    selectedClusterDetails,
    selectedCluster,
    hoveredPaper,
    upsertLayer,
    removeLayer,
    removePaperLayers,
    setPromptingSelection,
    searchQuery,
    mapName,
    setSelectedClusterDetails,
    setHoveredPaper,
    hoveredCluster,
    setSelectedPaperDetails,
    selectedPaperDetails,
    setHoveredCluster,
  } = useContext(MapContext);
  const snackbar = useSnackbar();
  const [cursor, setCursor] = useState<string>("default");
  const { loading, search } = useSearch();
  const location = useLocation();
  const isDebugMode = location.search.includes('debug');

  const [paperRectangleHoverTimeout, setPaperRectangleHoverTimeout] =
    useState<any>(null); // Timeout for paper rectangle hover (when a paper rectangle is hovered for > K ms, show the paper details)
  const [hoveredPaperRectangleId, setHoveredPaperRectangleId] = useState<
    number | null
  >(null); // ID of the paper rectangle that is currently hovered (with timeout)
  const [containerStyle, setContainerStyle] = useState<any>(
    DEFAULT_CONTAINER_STYLE
  );
  const [orderedConfigurations, setOrderedConfigurations] = useState<
    ISourceInformation[]
  >([]);
  const [lastHoveredFeature, setLastHoveredFeature] = useState<{
    id: number;
    source: string;
    sourceLayer: string;
  } | null>(null);
  const [lastSelectedFeature, setLastSelectedFeature] = useState<{
    id: number;
    source: string;
    sourceLayer: string;
  } | null>(null);

  const [mapStyle, setMapStyle] = useState<StyleSpecification>({
    version: 8,
    sources: {},
    layers: [],
  });

  const [selectedFeature, setSelectedFeature] = useState({
    id: null,
    type: null, // 'corpus' or 'cluster'
  });

  const [containerWidth, setContainerWidth] = useState(window.innerWidth); // Add state for container width
  const [map, setMap] = useState<any>(null);

  const mapRef = useRef<MapRef>();

  const getSourceForLayerId = (sourceLayer: string) => {
    if (sourceLayer in sourceJSON.sources) {
      return sourceLayer;
    } else {
      return "default";
    }
  };
  const hasClusterLayerPrefix = (sourceLayer: string) => {
    return config.cluster_layer_prefixes?.some((prefix) =>
      sourceLayer.startsWith(prefix)
    );
  };

  const isClusterLabelLayer = (sourceLayer: string) => {
    return (
      hasClusterLayerPrefix(sourceLayer) && sourceLayer.endsWith("_labels")
    );
  };
  useEffect(() => {
    if (mapRef.current) {
      const _map = mapRef.current.getMap();
      //set default cursor (pointer is set by mapbox-gl on hover over features)
      _map.getCanvas().style.cursor = "default";

      // Usage
      const colors = ["red", "yellow", "grey", "blue", "lila", "green"];
      colors.forEach((color) => addCustomIcon(_map, color));
      _map.on("zoom", () => {
        setZoom(_map.getZoom());
      });

      const handleViewportChange = () => {
        const bounds = _map.getBounds();

        setViewport({ sw: bounds.getSouthWest(), ne: bounds.getNorthEast() });
      };
      _map.on("zoom", handleViewportChange);
      _map.on("dragend", handleViewportChange);
      setMap(_map);
    }
  }, [mapRef, mapRef.current, targetCenter]);

  useEffect(() => {
    const SIDEBAR_WIDTH = 400;
    // Function to update the container width
    const updateContainerWidth = () => {
      const sidebarWidth = sidebarOpen ? SIDEBAR_WIDTH : 0; // Assume sidebar width is 500px
      const newWidth = window.innerWidth - sidebarWidth;
      setContainerStyle({
        ...containerStyle,
        width: `${newWidth}px`,
        backgroundColor: darkmode ? "#222" : "#d5e4ff",
      });
    };

    // Call it initially and whenever sidebarOpen changes
    updateContainerWidth();

    // Optional: Adjust the width when the window resizes
    window.addEventListener("resize", updateContainerWidth);
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, [sidebarOpen, darkmode]); // Only re-run when sidebarOpen changes
  const resetFeatureState = (featureId: any, featureType: any) => {
    const state = { select: false }; // Resetting the selection state

    if (map) {
      // Ensure the map object is defined
      if (featureType === "cluster") {
        for (const clusterLayerPrefix of config.cluster_layer_prefixes ?? []) {
          const clusterLabelLayerId = clusterLayerPrefix + "_labels";
          const clusterLineLayerId = clusterLayerPrefix + "_line";
          const clusterAreaLayerId = clusterLayerPrefix + "_area";

          const paramLabels = {
            source: getSourceForLayerId(clusterLabelLayerId),
            id: featureId,
            sourceLayer: clusterLabelLayerId,
          };

          const paramArea = {
            source: getSourceForLayerId(clusterAreaLayerId),
            id: featureId,
            sourceLayer: clusterAreaLayerId,
          };

          const paramStroke = {
            source: getSourceForLayerId(clusterLineLayerId),
            id: featureId,
            sourceLayer: clusterLineLayerId,
          };

          try {
            map.setFeatureState(paramLabels, state);
          } catch (e) {
            console.log(e);
          }

          try {
            map.setFeatureState(paramArea, state);
          } catch (e) {
            console.log(e);
          }
          try {
            map.setFeatureState(paramStroke, state);
          } catch (e) {
            console.log(e);
          }
        }
      } else if (featureType === "corpus") {
        // Reset state for the corpus layer
        try {
          map.setFeatureState(
            { source: "default", id: featureId, sourceLayer: "paper_labels" },
            state
          );
        } catch (e) {
          console.log(e);
        }
        try {
          if (map.getLayer("search_landmarks_with_titles")) {
            map.setFeatureState(
              { source: "search_landmarks_with_titles", id: featureId },
              state
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const selectFeature = (featureId: any, featureType: any) => {
    // Reset the state of previously selected feature
    if (selectedFeature.id && map) {
      resetFeatureState(selectedFeature.id, selectedFeature.type);
    }

    // Set the new feature as selected
    setSelectedFeature({ id: featureId, type: featureType });
    setFeatureState(featureId, featureType, true); // true for selected
  };

  const deselectFeature = () => {
    if (selectedFeature.id && map) {
      resetFeatureState(selectedFeature.id, selectedFeature.type);
    }
    setSelectedFeature({ id: null, type: null });
  };

  const onMapClick = useCallback(
    (event: any) => {
      const feature = event.features && event.features[0];
      if (feature && feature.id) {
        const getFeatureType = (feature: any) => {
          if (hasClusterLayerPrefix(feature.sourceLayer ?? "")) {
            return "cluster";
          } else if (
            feature.source === "author_papers" ||
            feature.source === "author_citations" ||
            feature.source === "paper_references" ||
            feature.source === "paper_citations" ||
            feature.source === "author_references" ||
            feature.sourceLayer === "paper_labels" ||
            feature.sourceLayer === "papers" ||
            feature.source.startsWith("search_landmarks") ||
            feature.sourceLayer?.startsWith("highlight_")
          ) {
            return "corpus";
          } else {
            return null;
          }
        };
        const featureType = getFeatureType(feature);
        if (featureType === "cluster") {
          //get bounds
          // Calculate the bounding box from the feature
          //get bounds from property bbox

          let bounds: any = null;
          if (
            "min_lng" in feature.properties &&
            "min_lat" in feature.properties &&
            "max_lng" in feature.properties &&
            "max_lat" in feature.properties
          ) {
            bounds = [
              feature.properties.min_lng,
              feature.properties.min_lat,
              feature.properties.max_lng,
              feature.properties.max_lat,
            ];
          }

          setSelectedCluster({
            cluster_id: feature.id,
            bounds: bounds,
          });
          setSelectedCorpusId(null);
        } else if (featureType === "corpus") {
          setSelectedCorpusId(feature.id);
          setSelectedCluster(null);
          setSelectedClusterDetails(null);
        }
      } else {
        setSelectedCluster(null);
        setSelectedClusterDetails(null);
        setSelectedCorpusId(null);
        setSelectedPaperDetails(null);
      }
    },
    [map]
  );

  useEffect(() => {
    if (mapRef.current) {
      // Check if there's a new corpus ID selected
      if (selectedCorpusId) {
        selectFeature(selectedCorpusId, "corpus");
      } else {
        // If no corpus is selected, ensure any previously selected corpus is deselected
        if (selectedFeature.type === "corpus") {
          deselectFeature();
        }
      }
    }
  }, [mapRef, selectedCorpusId]); // Only re-run when selectedCorpusId changes

  useEffect(() => {
    if (selectedPaperDetails) {
      const layerProps = createLandmarkLayerProps(
        createLandmarkLayerConfig({
          type: "landmark",
          source: "selected_paper",
          layer_name: "selected_paper",
          tag: "selected_paper",
          min_zoom: 0,
          max_zoom: 24,
        }),
        false,
        true
      );
      const selectedPaperHighlight: IHighlightLayer = {
        source: {
          id: "selected_paper",
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: selectedPaperDetails.geometry,
              },
            ],
          },
        },
        layer: layerProps,
      };
      upsertLayer(
        "selected_paper",
        selectedPaperHighlight.layer as any,
        selectedPaperHighlight.source
      );
    } else {
      removeLayer("selected_paper");
    }
  }, [selectedPaperDetails]);

  useEffect(() => {
    // Check if there's a new cluster ID selected
    let clusterData = selectedCluster;

    if (
      selectedClusterDetails &&
      selectedClusterDetails.cluster_id !== selectedCluster?.cluster_id
    ) {
      //case where new cluster was selected, but the details are not replaced/reset
      return;
    }
    if (selectedClusterDetails) {
      clusterData = selectedClusterDetails; //contains more information (e.g. geometry)
      const bounding_box = selectedClusterDetails.bounding_box;
      const bounds = [
        bounding_box.coordinates[0][0][0],
        bounding_box.coordinates[0][0][1],
        bounding_box.coordinates[0][2][0],
        bounding_box.coordinates[0][2][1],
      ];

      clusterData["bounds"] = bounds;
    }
    if (clusterData) {
      selectFeature(clusterData.cluster_id, "cluster");

      if (clusterData.bounds) {
        //bounds are min_lat, min_lng, max_lat, max_lng
        const bounds = clusterData.bounds;
        //fit Bounds
        map.fitBounds(
          [
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]],
          ],
          { padding: 100 }
        );
      }
      if (clusterData.geometry) {
        const layerProps = createPolygonLayerProps(
          createPolygonConfig({
            type: "polygon",
            source: "selected_cluster",
            layer_name: "selected_cluster",
            tag: "selected_cluster",
            min_zoom: 0,
            max_zoom: 24,
          }),
          true
        );
        const selectedClusterHighlight: IHighlightLayer = {
          source: {
            id: "selected_cluster",
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: clusterData.geometry,
                },
              ],
            },
          },
          layer: layerProps,
        };
        //add geometry to highlightFeatures
        upsertLayer(
          "selected_cluster",
          selectedClusterHighlight.layer as any,
          selectedClusterHighlight.source
        );
      }
    } else {
      removeLayer("selected_cluster");

      // If no cluster is selected, ensure any previously selected cluster is deselected
      if (selectedFeature.type === "cluster") {
        deselectFeature();
      }
    }
  }, [selectedCluster?.cluster_id, selectedClusterDetails?.cluster_id]); // Only re-run when selectedClusterId changes

  const setFeatureState = (
    featureId: any,
    featureType: any,
    isSelected: any
  ) => {
    for (const clusterLayerPrefix of config.cluster_layer_prefixes ?? []) {
      const clusterLabelLayerId = clusterLayerPrefix + "_labels";
      const clusterLineLayerId = clusterLayerPrefix + "_line";
      const clusterAreaLayerId = clusterLayerPrefix + "_area";

      const paramLabels = {
        source: getSourceForLayerId(clusterLabelLayerId),
        id: featureId,
        sourceLayer: clusterLabelLayerId,
      };

      const paramArea = {
        source: getSourceForLayerId(clusterAreaLayerId),
        id: featureId,
        sourceLayer: clusterAreaLayerId,
      };

      const paramStroke = {
        source: getSourceForLayerId(clusterLineLayerId),
        id: featureId,
        sourceLayer: clusterLineLayerId,
      };

      try {
        map.setFeatureState(paramLabels, { select: isSelected });
      } catch (e) {
        console.log(e);
      }

      try {
        map.setFeatureState(paramArea, { select: isSelected });
      } catch (e) {
        console.log(e);
      }
      try {
        map.setFeatureState(paramStroke, { select: isSelected });
      } catch (e) {
        console.log(e);
      }
    }
    const state = { select: isSelected };

    if (featureType === "corpus") {
      map.setFeatureState(
        { source: "default", id: featureId, sourceLayer: "paper_labels" },
        state
      );

      if (map.getLayer("search_landmarks_with_titles")) {
        map.setFeatureState(
          { source: "search_landmarks_with_titles", id: featureId },
          state
        );
      }
    }
  };

  const [layerConfigurations, setLayerConfigurations] = useState<
    ISourceInformation[]
  >([]);

  const [hoverInfo, setHoverInfo] = useState<{
    longitude: number;
    latitude: number;
    text: string;
  } | null>(null);

  const [customSources, setCustomSources] = useState<ISourceInformation[]>([]);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([]);
  const [mapViewport, setMapViewport] = useState({
    latitude: targetCenter ? targetCenter.lat : 0,
    longitude: targetCenter ? targetCenter.lng : 0,
    zoom: targetCenter ? targetCenter.zoom : 1,
    maxBounds: [
      [-130, -90],
      [130, 90],
    ],
    minZoom: config.min_zoom,
    maxZoom: config.max_zoom, //!!config.max_zoom ? config.max_zoom - 0.001 : 24,
  });
  useEffect(() => {
    // Function to remove highlight layers if they exist
    function removeHighlightLayersIfExist() {
      const highlightTypes = [
        "author_paper",
        "author_citation",
        "author_reference",
        "paper_citation",
        "paper_reference",
        "search",
        "cluster",
      ];
      for (const highlightType of highlightTypes) {
        removeLayer("hovered_" + highlightType);
      }
    }

    // This effect should only run when `hoveredPaper` changes
    if (hoveredPaper && hoveredPaper.source === "list") {
      const corpusId = "hovered_paper_" + hoveredPaper.id.toString();
      /*const highlightPresent = highlightLayers.some(
        (highlightLayer) =>
          highlightLayer.layer.id === "hovered_paper__" + corpusId
      );*/

      const layerProps = createLandmarkLayerProps(
        createLandmarkLayerConfig({
          type: "landmark",
          source: "hovered_paper",
          layer_name: "hovered_" + hoveredPaper.type,
          tag: "hover",
          min_zoom: 0,
          max_zoom: 24,
        }),
        false,
        true
      );

      const hoverHighlight = {
        source: {
          //id: "hovered_paper",
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  title: "", //TODO make sure the text is not actually displayed
                },
                geometry: hoveredPaper.geometry,
              },
            ],
          },
        },
        layer: layerProps,
      };

      upsertLayer(
        layerProps.id,
        hoverHighlight.layer as any,
        hoverHighlight.source
      );
    } else {
      removeHighlightLayersIfExist();
    }
  }, [hoveredPaper]); // Only depend on `hoveredPaper`

  useEffect(() => {
    //only update/initialize when the default source changed (or glyphs). Otherwise entire map will rerender everytime a geojson source was changed
    //NOTE: assumes that the tile source is called 'default'
    setMapStyle({
      version: sourceJSON.version as any,
      glyphs: sourceJSON.glyphs,
      sources: sourceJSON.sources,
      layers: [], //will be set as children of the react component
    });
  }, [sourceJSON.sources.default, sourceJSON.glyphs]);

  const onMouseMove = useCallback(
    (event: any) => {
      const feature = event.features && event.features[0];

      if (feature && feature.id) {
        if (hasClusterLayerPrefix(feature.sourceLayer ?? "")) {
          if (isClusterLabelLayer(feature.sourceLayer ?? "")) {
            setHoveredCluster({
              cluster_id: feature.id,
              source: "label",
              label: `${feature.properties.label}`,
              geometry: feature.geometry,
            });
          } else {
            setHoveredCluster({
              cluster_id: feature.id,
              source: "map",
            });
          }
          setHoveredPaper(null);
        } else if (
          feature.source === "author_papers" ||
          feature.source === "author_citations" ||
          feature.source === "paper_references" ||
          feature.source === "paper_citations" ||
          feature.source === "author_references" ||
          feature.sourceLayer === "paper_labels" ||
          feature.sourceLayer === "selected_paper" ||
          feature.sourceLayer === "paper_polygons" ||
          feature.source.startsWith("search_landmarks") ||
          feature.sourceLayer?.startsWith("highlight_")
        ) {
          let hoverType = feature.sourceLayer || feature.source;
          if (hoverType.startsWith("highlight_")) {
            hoverType = "highlight";
          }
          if (hoveredPaper?.id !== feature.id) {
            setHoveredPaper({
              id: feature.id,
              source: "map",
              geometry: feature.geometry,
              title: feature.properties.title,
              type: hoverType,
              meta: null,
            });
          }
          setHoveredCluster(null);
        } else if (feature.sourceLayer === "papers") {
          if (
            hoveredPaperRectangleId !== feature.id &&
            (!hoveredPaper || hoveredPaper.id !== feature.id)
          ) {
            if (paperRectangleHoverTimeout) {
              clearTimeout(paperRectangleHoverTimeout);
            }
            setHoveredPaperRectangleId(feature.id);
            const newTimeout = setTimeout(async () => {
              try {
                const paperDetails = await fetchPaperDetails(mapName,feature.id);

                const polygonFeature = feature.geometry;
                //create point geometry from polygon (centroid)
                const pointGeometry: any = {
                  type: "Point",
                  coordinates: [
                    //mean
                    _.mean(
                      polygonFeature.coordinates[0]
                        .map((coord: any) => coord[0])
                        .slice(0, 4)
                    ),
                    _.mean(
                      polygonFeature.coordinates[0]
                        .map((coord: any) => coord[1])
                        .slice(0, 4)
                    ),
                  ],
                };

                const hoveredPaperMeta = {
                  journal: paperDetails.journal,
                  citationcount: paperDetails.citationcount,
                  authors: paperDetails.authors,
                  year: paperDetails.year,
                  tldr: paperDetails.tldr,
                };
                setHoveredPaper({
                  ...paperDetails,
                  geometry: pointGeometry,
                  source: "map",
                  meta: hoveredPaperMeta,
                });
                setHoveredPaperRectangleId(null); // Clear the rectangle ID once the action is triggered
              } catch (e) {
                snackbar.showSnackbar(
                  "Failed to fetch hover information",
                  "error"
                );
              }
            }, 200);
            setPaperRectangleHoverTimeout(newTimeout);
          }
        }
        setCursor("pointer");
      } else {
        if (paperRectangleHoverTimeout) {
          clearTimeout(paperRectangleHoverTimeout);
        }
        setHoveredPaperRectangleId(null);
        setHoveredPaper(null);
        setHoveredCluster(null);
        setCursor("default");
      }
    },
    [hoveredPaperRectangleId, hoveredPaper, paperRectangleHoverTimeout]
  );

  // useEffect for hovered papers
  useEffect(() => {
    if (hoveredPaper && hoveredPaper.source === "map" && map) {
      // Set the hover state for the hovered paper
      const paramsPaperLabels = {
        source: "default",
        id: hoveredPaper.id,
        sourceLayer: "paper_labels",
      };

      const paramsSeachLandmarks = {
        source: "search_landmarks_with_titles",
        id: hoveredPaper.id,
      };

      map.setFeatureState(paramsPaperLabels, { hover: true });
      if (map.getLayer("search_landmarks_with_titles")) {
        map.setFeatureState(paramsSeachLandmarks, { hover: true });
      }
      return () => {
        // Reset the hover state when the hoveredPaper changes
        map.setFeatureState(paramsPaperLabels, { hover: false });
        if (map.getLayer("search_landmarks_with_titles")) {
          map.setFeatureState(paramsSeachLandmarks, { hover: false });
        }
      };
    }
  }, [hoveredPaper, map]);

  // useEffect for hovered clusters
  useEffect(() => {
    if (hoveredCluster && map) {
      if (hoveredCluster.source === "list" && hoveredCluster.geometry) {
        const layerProps = createPolygonLayerProps(
          createPolygonConfig({
            type: "polygon",
            source: "hovered_cluster",
            layer_name: "hovered_cluster",
            tag: "hovered_cluster",
            min_zoom: 0,
            max_zoom: 24,
          }),
          true
        );
        const hoveredClusterHighlight: IHighlightLayer = {
          source: {
            id: "hovered_cluster",
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: hoveredCluster.geometry,
                },
              ],
            },
          },
          layer: layerProps,
        };

        //add geometry to highlightFeatures
        upsertLayer(
          "hovered_cluster",
          hoveredClusterHighlight.layer as any,
          hoveredClusterHighlight.source
        );
      }

      // Set the hover state for all layers of the hovered cluster
      for (let i = 0; i < 4; i++) {
        if (hoveredCluster.source === "map") {
          const labelParams = {
            source: "default",
            id: hoveredCluster.cluster_id,
            sourceLayer: `cluster_labels_${i}`,
          };
          map.setFeatureState(labelParams, { hover: true });
        }
        const areaParams = {
          source: "default",
          id: hoveredCluster.cluster_id,
          sourceLayer: `cluster_area_${i}`,
        };

        const strokeParams = {
          source: "default",
          id: hoveredCluster.cluster_id,
          sourceLayer: `cluster_line_${i}`,
        };
        //map.setFeatureState(areaParams, { hover: true });
        map.setFeatureState(strokeParams, { hover: true });
      }

      // Cleanup function to reset hover state
      return () => {
        for (let i = 0; i < 4; i++) {
          const labelParams = {
            source: "default",
            id: hoveredCluster.cluster_id,
            sourceLayer: `cluster_labels_${i}`,
          };
          const areaParams = {
            source: "default",
            id: hoveredCluster.cluster_id,
            sourceLayer: `cluster_area_${i}`,
          };

          const strokeParams = {
            source: "default",
            id: hoveredCluster.cluster_id,
            sourceLayer: `cluster_line_${i}`,
          };
          map.setFeatureState(labelParams, { hover: false });
          map.setFeatureState(areaParams, { hover: false });
          map.setFeatureState(strokeParams, { hover: false });
        }
      };
    } else if (!hoveredCluster || hoveredCluster.source !== "list") {
      removeLayer("hovered_cluster");
    }
  }, [hoveredCluster, map]);

  const addCustomIcon = async (map: any, color: string) => {
    try {
      const image = await new Promise((resolve, reject) => {
        const img = new Image(); // Create a new Image instance
        img.onload = () => resolve(img); // Resolve the promise once the image has loaded
        img.onerror = reject; // Reject the promise if there's an error loading the image
        img.src = `/landmark_${color}.png`; // Set the source of the image
      });

      if (!map.hasImage(`landmark_${color}`)) {
        map.addImage(`landmark_${color}`, image as any); // Add the custom icon to the map
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  let combinedLayers: CombinedLayerType[] = [
    ...sourceJSON.layers
      .filter((layer: ILayer) => layer.visible ?? true)
      .map((layer: any) => {
        if ("source-layer" in layer) {
          return {
            id: layer.id,
            type: "layer" as any,
            data: layer,
          };
        } else {
          return {
            id: layer.id,
            type: "highlight" as any,
            data: layer,
          };
        }
      }),
  ];

  //custom Logic: exclude highlight layers when there is a search result being displayed
  if (searchPaperResults || selectedAuthorId || selectedCorpusId) {
    combinedLayers = combinedLayers.filter(
      (layer) => !layer.id.startsWith("highlight_")
    );
  }

  if (selectedAuthorId || selectedCorpusId) {
    combinedLayers = combinedLayers.filter(
      (layer) => layer.id.indexOf("search") === -1
    );
  }

  const interactiveClusterLayerIds = ["cluster_labels_0", "cluster_labels_1"];
  for (const clusterLayerPrefix of config.cluster_layer_prefixes ?? []) {
    interactiveClusterLayerIds.push(clusterLayerPrefix + "_labels");
    interactiveClusterLayerIds.push(clusterLayerPrefix + "_areas");
    interactiveClusterLayerIds.push(clusterLayerPrefix + "_lines");
  }

  const allInteractiveLayerIds = [
    "paper_labels",
    "papers",
    "author_papers",
    "author_citations",
    "author_references",
    "paper_citations",
    "paper_references",
    "search_landmarks_with_titles",
    "search_landmarks_without_titles",
    "highlight_0",
    "highlight_1",
    "highlight_2",
    "highlight_3",
    "highlight_4",
    "highlight_5",
    "highlight_6",
    "highlight_7",
    "highlight_8",
    "highlight_9",
    "selected_paper",
    ...interactiveClusterLayerIds,
  ];

  return (
    <Map
      ref={mapRef as any}
      mapStyle={mapStyle}
      initialViewState={mapViewport}
      style={containerStyle}
      cursor={cursor}
      onClick={onMapClick}
      onMouseMove={onMouseMove}
      //onMouseLeave={onMouseOut}
      interactiveLayerIds={allInteractiveLayerIds}
      attributionControl={false}
    >
      {combinedLayers
        .slice()
        .reverse()
        .map((item, r_index) => {
          //NOTE: reversing is necessary otherwise beforeId will complain as the referenced layer does not exist at the time of insertion (it is inserted later)
          const index = combinedLayers.length - 1 - r_index;

          const layer = item.data;
          const beforeId =
            index < combinedLayers.length - 1
              ? combinedLayers[index + 1].id
              : undefined;
          if (item.type === "layer") {
            return <Layer key={layer.id} {...layer} beforeId={beforeId} />;
          } else if (item.type === "highlight") {
            const source = sourceJSON.sources[layer.source];
            return (
              <Source id={layer.id} key={layer.id} {...(source as any)}>
                <Layer key={layer.id} {...layer} beforeId={beforeId} />
              </Source>
            );
          }
        })}
      {hoveredPaper?.geometry && hoveredPaper.source === "map" && (
        <Popup
          longitude={hoveredPaper.geometry.coordinates[0] as number}
          latitude={hoveredPaper.geometry.coordinates[1] as number}
          closeButton={false}
          offset={[0, -20] as any}
          className="hovered-paper-popup"
        >
          <PaperHoverPopup hoveredPaper={hoveredPaper} />
        </Popup>
      )}
      {hoveredCluster?.geometry && hoveredCluster.source === "label" && (
        <Popup
          longitude={hoveredCluster.geometry.coordinates[0] as number}
          latitude={hoveredCluster.geometry.coordinates[1] as number}
          closeButton={false}
          offset={[0, -20] as any}
          className="hovered-cluster-popup"
        >
          <ClusterHoverPopup hoveredCluster={hoveredCluster} />
        </Popup>
      )}
      {isDebugMode && searchPaperResults && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            position: "absolute",
            bottom: "30px",
            left: "20%",
            transform: "translateX(-50%)",
          }}
        >
          Showing {searchPaperResults!.length} results
        </div>
      )}
      {searchPaperResults && zoom>MIN_ZOOM_FOR_AREA_SEARCH && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
              position: "absolute",
              bottom: "30px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
           
              <AreaSearch />
            {false && isDebugMode && (
              <Button
                style={{
                  borderRadius: "20px",
                  padding: "7px 10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                }}
                onClick={() => {
                  setPromptingSelection({
                    selectionType: "search",
                    selectionArgs: {
                      map_name: mapName,
                      q: searchQuery,
                      limit: 1000,
                      exact_matches: true,
                    },
                  });
                }}
              >
                Prompt Search Results
              </Button>
            )}
            {isDebugMode && (
              <CopySelectionButton
                selectionConfig={{
                  selection_type: "search",
                map_name: mapName,
                q: searchQuery,
                limit: 1000,
                exact_matches: true,
              }}
            />)}
            {isDebugMode && (
              <Button
                style={{
                  borderRadius: "20px",
                  padding: "7px 10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                }}
                onClick={() => {
                  setPromptingSelection({
                    selectionType: "bounds",
                    selectionArgs: {
                      map_name: mapName,
                      bounds: [
                        viewport.sw.lng,
                        viewport.sw.lat,
                        viewport.ne.lng,
                        viewport.ne.lat,
                      ],
                    },
                  });
                }}
              >
                Prompt Bounds
              </Button>
            )}
            {isDebugMode && (
              <CopySelectionButton
                selectionConfig={{
                  selection_type: "bounds",
                map_name: mapName,
                bounds: [
                  viewport.sw.lng,
                  viewport.sw.lat,
                  viewport.ne.lng,
                  viewport.ne.lat,
                ],
              }}
            />)}
          </div>
        </div>
      )}
      {!searchPaperResults && isDebugMode && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            position: "absolute",
            bottom: "30px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button
            style={{
              borderRadius: "20px",
              padding: "7px 10px",
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
            onClick={() => {
              setPromptingSelection({
                selectionType: "bounds",
                selectionArgs: {
                  map_name: mapName,
                  bounds: [
                    viewport.sw.lng,
                    viewport.sw.lat,
                    viewport.ne.lng,
                    viewport.ne.lat,
                  ],
                },
              });
            }}
          >
            Prompt Bounds
          </Button>

          {isDebugMode && (
            <CopySelectionButton
              selectionConfig={{
                selection_type: "bounds",
              map_name: mapName,
              bounds: [
                viewport.sw.lng,
                viewport.sw.lat,
                viewport.ne.lng,
                viewport.ne.lat,
              ],
            }}
          />)}
        </div>
      )}
    </Map>
  );
}

export default MapReactMap;
