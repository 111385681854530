import './PaperList.scss';
import React, { useContext } from "react";
import { List, AutoSizer } from "react-virtualized";
import IAuthorMeta from "../../interfaces/authorMeta";
import IPaperMeta from "../../interfaces/paperMeta";
import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import PaperListItem from "./PaperListItem";

export default function PaperList({ papers,type }: { papers: IPaperMeta[],type:HoveredPaperType }) {
  const { setSelectedCorpusId } = useContext(MapContext);
  // Row renderer for react-virtualized 
  const rowRenderer = ({ key, index, style }: any) => {
    const paper = papers[index];

    return (
      <div
        className="paperListItemParent"
        style={{
          ...style,
          height: "80px",
          maxHeight: "80px",
          overflowY: "hidden",
          borderBottom: "1px solid lightgrey",
        }}
        key={index}
      >
        <PaperListItem paper={paper} type={type}/>
      </div>
    );
  };

  return (
    <div style={{ height: "calc(100vh - 170px)", width: "100%" }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
          style={{padding:0}}
            width={width}
            height={height}
            rowCount={papers.length}
            rowHeight={100} // Adjust based on your content
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
}
