import './ClusterPictureDisplay.scss';
import { Button } from "@mui/material";
import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
import React, { useContext, useState } from "react";
import "./PaperPictureDisplay.scss"; // Import the CSS file for styles
import { fetchPaperDetailsWithCoordinates, generateClusterPicture } from "../../requests";
import MapContext from "../../contexts/MapContext";
import IClusterDetails from "../../interfaces/clusterDetails";
import { CircularProgress, IconButton } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
export default function ClusterPictureDisplay({
  clusterDetails,
}: {
  clusterDetails: IClusterDetails;
}) {
  const { mapName, selectedCorpusId, setSelectedClusterDetails } = useContext(MapContext);
  const [isGenerating, setIsGenerating] = useState(false);

  const generatePicture = async () => {
    setIsGenerating(true);
    try {
      const updatedClusterDetails = await generateClusterPicture(mapName, clusterDetails.cluster_id, "tf");
      setSelectedClusterDetails(updatedClusterDetails);
    } catch (e) {
      console.error(e);
    } finally {
      setIsGenerating(false);
    }
  }

  if (!clusterDetails.image_url) {
    return  <div className="clusterPicture" style={{ position: "relative" }}>
      <img
          className="clusterPictureImage" // Assign a class for targeting in CSS
          src={"/cover3.webp"}
          alt="picture could not be loaded"
        />
      <div className="overlay-text">
        
        <span style={{'fontSize':'11px',textAlign:'left'}}>Topic</span><br/>
        <span>{clusterDetails.label}
        </span>
        </div>
    </div>
  } else {
    return (
      <div className="clusterPicture" style={{ position: "relative" }}>
        <img
          className="clusterPictureImage" // Assign a class for targeting in CSS
          src={clusterDetails.image_url || "/no_picture.png"}
          alt="picture could not be loaded"
        />
        <IconButton
          className="btn-regenerate"
          disabled={isGenerating}
          onClick={generatePicture}
          style={{ position: "absolute", top: 10, right: 10, backgroundColor: "rgba(255, 255, 255, 0.7)" }}
          size="small"
        >
          {isGenerating ? <CircularProgress size={20} /> : <RefreshIcon />}
        </IconButton>
      </div>
    );
  }
}