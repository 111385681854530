// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import jwt_decode, { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext({} as any);
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens") as string) : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("tokens") ? jwtDecode(localStorage.getItem("tokens") as string) : null
  );

  const loginUser = (tokens:any) => {
    setAuthTokens(tokens);
    setUser(jwtDecode(tokens.access_token));
    localStorage.setItem("tokens", JSON.stringify(tokens));
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("tokens");
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.access_token));
    }
  }, [authTokens]);

  return (
    <AuthContext.Provider value={{ user, authTokens, loginUser, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};