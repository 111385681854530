export const GOOGLE_MAPS_COLORS={
    urbanArea:"#f8f7f7",
    water:"#94b4e9", //#5C9BE2",
    majorRoad:"#FC9805",
    minorRoad:"#FFD600",
    park:"#80B755",
    building:"#e8e9ed",
    buildingOutline: "#d5d9e5",
    plain:'#f2f3f4',
    borders:"#C8D1D1",
    ruralArea:"#E3E2DD",
    subUrbanArea:"#9C9C9C",
    beach:"#F7E9A5",
    desert:"#D2C3A3",
    forest:"#5B7F52",
    mountain:"#AC875E",
    loadingBackground:"#010F09",
    searchLandmarks:"#e94436"
}

export const HOVER_COLOR="#1058c9"
export const SELECTION_COLOR="#b31512";
export const GOOGLE_MAPS_COLORS_DETAILS={
    green0:"#bbe2c6",
    green1:"#a8dab5",
    green2:"#94d2a4",
    green3:"#87c399",
    green4:"#86c299",
    greenGray:"#b3cfbd",
    desertFar:"#faf6f0",
    ice:"ffffff",
    orange0:"#f1e1ca",
    orange1:"#ecdcc0",
    orange2:"#eddbc1",
    orange3:"#dacdb3",
    desert0:"#f8f4eb",
    desert1:"#f2ebdb",
    desert2:"#eddcc1",
    desertClose:"#f2e9d7",
    park: "#b2debe",
    urban:"#e8eaed",
    urbanClose:"#f8f9fa",
    cityCenter:"#fefaed",












}