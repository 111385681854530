import { Button, CircularProgress, Divider, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import {
  fetchAuthorCitations,
  fetchAuthorDetails,
  fetchAuthorPapers,
  fetchAuthorReferences,
} from "../../requests";
import _, { zip } from "lodash";

import MapContext, {
  HoveredPaperType,
} from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";
import IPaperMeta from "../../interfaces/paperMeta";
import PaperList from "../lists/PaperList";
import CitationCountDisplay from "../paper/CitationCountDisplay";

export default function AuthorDetailsPanel() {
  const {
    selectedAuthorId,
    selectedAuthorDetails,
    setSelectedAuthorId,
    upsertLayer,
    removeLayer,
    removeAuthorLayers,
    setSelectedAuthorDetails,
    mapName,
  } = useContext(MapContext);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingPapers, setIsLoadingPapers] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [papers, setPapers] = useState<IPaperMeta[]>([]);
  const snackbar = useSnackbar();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Reset the ref when the component re-mounts
  useEffect(() => {
    isMounted.current = true;
  }, [selectedAuthorId]);

  useEffect(() => {
    if (selectedAuthorId) {
      setCurrentTab(0);

      setIsLoadingDetails(true);
      fetchAuthorDetails(selectedAuthorId)
        .then((authorDetails) => {
          if (isMounted.current) {
            setSelectedAuthorDetails(authorDetails);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            snackbar.showSnackbar("Error fetching author details", "error");
            setSelectedAuthorId(null); //hide the panel
          }
        })
        .finally(() => {
          if (isMounted.current) setIsLoadingDetails(false);
        });
    } else {
      setSelectedAuthorDetails(null);
      removeLayer("author_papers");
      removeLayer("author_citations");
      removeLayer("heatmap_author_papers");
      removeLayer("heatmap_author_citations");
    }
  }, [selectedAuthorId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (selectedAuthorId) {
      const activeTab = currentTab;

      if (activeTab == 0) {
        setPapers([]);
        setIsLoadingPapers(true);
        fetchAuthorPapers(mapName, selectedAuthorId)
          .then((response) => {
            if (!isMounted.current || activeTab !== currentTab) return;

            removeAuthorLayers();
            setPapers(response.papers);

            const layers = response.layers;
            const sources = response.sources;
            const zipped = zip(layers, sources);
            for (const [layer, source] of zipped) {
              upsertLayer(layer!.id, layer!, source, "author_paper_labels");
            }
          })
          .catch((error) => {
            if (isMounted.current) {
              console.log(error);
              snackbar.showSnackbar("Error fetching author papers", "error");
            }
          })
          .finally(() => {
            if (isMounted.current) setIsLoadingPapers(false);
          });
      } else if (activeTab == 1) {
        setPapers([]);
        setIsLoadingPapers(true);
        fetchAuthorCitations(mapName, selectedAuthorId)
          .then((response) => {
            if (!isMounted.current || activeTab !== currentTab) return;

            removeAuthorLayers();
            setPapers(response.papers);
            const layers = response.layers;
            const sources = response.sources;
            const zipped = zip(layers, sources);
            for (const [layer, source] of zipped) {
              upsertLayer(layer!.id, layer!, source, "citation_labels");
            }
          })
          .catch((error) => {
            if (isMounted.current) {
              console.log(error);
              snackbar.showSnackbar("Error fetching author citations", "error");
            }
          })
          .finally(() => {
            if (isMounted.current) setIsLoadingPapers(false);
          });
      } else if (activeTab == 2) {
        setIsLoadingPapers(true);
        setPapers([]);

        fetchAuthorReferences(mapName, selectedAuthorId)
          .then((response) => {
            if (!isMounted.current || activeTab !== currentTab) return;

            removeAuthorLayers();
            setPapers(response.papers);

            const layers = response.layers;
            const sources = response.sources;
            const zipped = zip(layers, sources);
            for (const [layer, source] of zipped) {
              upsertLayer(layer!.id, layer!, source, "reference_labels");
            }
          })
          .catch((error) => {
            if (isMounted.current) {
              console.log(error);
              snackbar.showSnackbar("Error fetching author references", "error");
            }
          })
          .finally(() => {
            if (isMounted.current) setIsLoadingPapers(false);
          });
      }
    }
  }, [selectedAuthorId, currentTab]);

  const getAuthorPaperCount = () => {
    if(currentTab==0 && papers && papers.length){
      return `(${papers.length})`;
    }
    return "";
  }

  const getCitationCount = () => {
    if(currentTab==1 && papers && papers.length){
      return `(${papers.length})`;
    }
    return "";
  };

  const getReferenceCount = () => {
    if(currentTab==2 && papers && papers.length){
      return `(${papers.length})`;
    }
    return "";
  }


  const getHoverType = (): HoveredPaperType => {
    if (currentTab == 0) {
      return "author_paper";
    } else if (currentTab == 1) {
      return "author_citation";
    } else if (currentTab == 2) {
      return "author_reference";
    }

    throw new Error("Invalid tab");
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        zIndex: 4,
        boxShadow: "1px 1px 4px #c5c5c5",
        backgroundColor: "white",
      }}
    >
      {selectedAuthorDetails && (
        <div style={{ flex: 1, overflowY: "auto" }}>
          <img
            src="/researcher.webp"
            alt="here is an image"
            style={{
              maxHeight: "250px",
              width: "400px",
              display: "block",
              margin: "auto",
              objectFit: "cover",
            }}
          />

          <div style={{ padding: "20px", paddingTop: 0 }}>
            <h3 style={{ textAlign: "left", marginBottom: "5px" }}>
              {selectedAuthorDetails.name}{" "}
            </h3>
            {selectedAuthorDetails.homepage && (
              <div style={{ marginBottom: "10px" }}>
                <a
                  href={selectedAuthorDetails.homepage}
                  style={{ textAlign: "left", fontSize: "11px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Homepage
                </a>
              </div>
            )}
            <div
              className="meta"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <CitationCountDisplay
                citationCount={selectedAuthorDetails.citationCount}
              />
            </div>
            <Divider />

            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab label={"Papers"+getAuthorPaperCount()} />
              <Tab label={"Citations"+getCitationCount()} />
              <Tab label={"References"+getReferenceCount()} />
            </Tabs>
            {isLoadingPapers && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <CircularProgress size={20} />
              </div>
            )}
            {papers && <PaperList papers={papers} type={getHoverType()} />}

            <Divider />
          </div>
        </div>
      )}
      {!selectedAuthorDetails && (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
      <div style={{ padding: "10px", borderTop: "1px solid #eee" }}>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            setSelectedAuthorId(null);
            setSelectedAuthorDetails(null);
            removeAuthorLayers();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
