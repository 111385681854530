import "./HoverPopup.scss";
import Skeleton from "@mui/material/Skeleton";
import { useContext, useEffect, useRef, useState } from "react";
import { fetchPaperDetails } from "../../requests";
import MapContext, { IHoveredPaper } from "../../contexts/MapContext";
import IAuthorMeta from "../../interfaces/authorMeta";
import IJournalMeta from "../../interfaces/journalMeta";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import YearDisplay from "../paper/YearDisplay";
import { Divider } from "@mui/material";
import { useSnackbar } from "../../contexts/SnackbarContext";

export default function PaperHoverPopup({
  hoveredPaper,
}: {
  hoveredPaper: IHoveredPaper;
}) {
  const { mapName, setSelectedCorpusId, setHoveredPaper } =
    useContext(MapContext);
  const snackbar = useSnackbar();
  const loadingPaperIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (hoveredPaper && !hoveredPaper.meta) {
      const paperId = hoveredPaper.id;
      loadingPaperIdRef.current = paperId; // Track the current loading paper ID
        
      // Fetch the paper details only if not already loaded
      fetchPaperDetails(mapName, paperId)
        .then((details) => {
          if (loadingPaperIdRef.current === paperId) {
            // Update only if the loading ID is the same as the current hoveredPaper ID
            setHoveredPaper({
              ...hoveredPaper,
              meta: details,
            });
          }
        })
        .catch((e: any) => {
          snackbar.showSnackbar("Error fetching paper details", "error");
        });

      // Cleanup function to reset loadingPaperIdRef if hoveredPaper changes
      return () => {
        loadingPaperIdRef.current = null;
      };
    }
  }, [hoveredPaper]);

  const getAuthorString = (
    authors: IAuthorMeta[] | undefined,
    maxCharacters: number = 50
  ) => {
    if (!authors || authors.length === 0) {
      return "authors unknown";
    }

    if (authors.length === 1) {
      const authorName = authors[0].name;
      if (authorName.length > maxCharacters) {
        return authorName.slice(0, maxCharacters) + "...";
      }
      return authorName;
    } else {
      const firstAuthor = authors[0].name;
      const suffix = " et al.";
      if (firstAuthor.length + suffix.length > maxCharacters) {
        return (
          firstAuthor.slice(0, maxCharacters - suffix.length) + "..." + suffix
        );
      }
      return firstAuthor + suffix;
    }
  };

  const getJournalString = (
    journal: IJournalMeta | undefined,
    maxCharacters: number = 50
  ) => {
    if (!journal || !journal.name) {
      return "";
    }

    const prefix = "(";
    const suffix = ")";
    const availableChars = maxCharacters - prefix.length - suffix.length;

    if (journal.name.length > availableChars) {
      return `${prefix}${journal.name.slice(0, availableChars)}...${suffix}`;
    }
    return `${prefix}${journal.name}${suffix}`;
  };

  const getNameForHoveredPaperType = (type: string) => {
    switch (type) {
      case "author_papers":
        return "Author Paper";
      case "paper_citations":
        return "Citation";
      case "paper_references":
        return "Reference";
      case "author_citations":
        return "Citation";
      case "author_references":
        return "Reference";
      case "paper_similar":
        return "Similar Paper";
      case "search_landmarks_with_titles":
        return "Search Result";
      case "search_landmarks_without_titles":
        return "Search Result";
      case "cluster":
        return "Cluster";
      case "highlight":
        return "Highlight";
      default:
        return "Paper";
    }
  };

  const getColorForHoveredPaperType = (type: string) => {
    switch (type) {
      case "author_papers":
        return "#ffeb03";
      case "paper_citations":
        return "#06ae14";
      case "paper_references":
        return "#009fbc";
      case "author_citations":
        return "#06ae14";
      case "author_references":
        return "#009fbc";
      case "search_landmarks_with_titles":
        return "#e84c3e";
      case "search_landmarks_without_titles":
        return "#e84c3e";
      case "highlight":
        return "#ff00ff";
      default:
        return "#000000";
    }
  };

  return (
    <div
      className={"popupHoverPaper " + "type-" + hoveredPaper.type}
      onClick={() => {
        setSelectedCorpusId(hoveredPaper.id);
      }}
    >
      <div style={{ textAlign: "left" }}>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            paddingBottom: "5px",
          }}
        >
          <div className="paperHeader">
            {hoveredPaper.type && getNameForHoveredPaperType(hoveredPaper.type)}
            {!hoveredPaper.type && "Paper"}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "4px",
              left: 0,
              height: "2px",
              width: "100%",
              backgroundColor: getColorForHoveredPaperType(
                hoveredPaper.type || ""
              ),
            }}
          ></div>
        </div>
      </div>

      <div className="paperTitle">{hoveredPaper.title}</div>
      <div className="meta">
        {!hoveredPaper.meta ? (
          <>
            <Skeleton variant="text" className="skeleton-text" width="100%" />
            <Skeleton variant="text" className="skeleton-text" width="100%" />
          </>
        ) : (
          <>
            <div className="metaRow">
              <YearDisplay year={hoveredPaper.meta.year} />
              <CitationCountDisplay
                citationCount={hoveredPaper.meta.citationcount}
              />
            </div>
            <div className="metaRow">
              <div>{getAuthorString(hoveredPaper.meta.authors)}</div>
              <div>{getJournalString(hoveredPaper.meta.journal)}</div>
            </div>
            {hoveredPaper.meta.tldr && (
              <div>
                <Divider />
                <div style={{ textAlign: "left" }}>
                  <b>TLDR</b>
                  <span
                    style={{
                      paddingLeft: "5px",
                      fontSize: "11px",
                    }}
                  >
                    {hoveredPaper.meta.tldr.text}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="hoverInfo">Click to view details</div>
    </div>
  );
}
