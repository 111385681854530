import './SidebarToggleButton.scss';
import { useContext } from "react";
import MapContext from "../contexts/MapContext";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function SidebarToggleButton({}) {
  const { sidebarOpen, setSidebarOpen } = useContext(MapContext);
  return (
    <IconButton
      onClick={() => {
        setSidebarOpen(!sidebarOpen);
      }}
      style={{
        left: sidebarOpen ? "380px" :"10px",
        zIndex:1000,
        backgroundColor:'white',
        border:'1px solid lightgrey',
      position: 'absolute',
          top: '50%',
      }}
      className="sidebarToggleButton"
    >
      {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  );
}
