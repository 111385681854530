import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  createGridLayer,
  createHeatmapLayer,
  createClusterLabelLayer,
  createClusterLayer,
  createHexbinLayer,
} from "../../requests";
import MapContext from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";

type LayerType = "hexbin" | "statistics" | "heatmap" | "cluster_labels" | "cluster";

interface LayerFormData {
  layerType: LayerType;
  selectionConfig: string;
  dataConfig: string;
  geometryConfig: string;
  styleConfig: string;
}

interface LayerCreationProps {
  onLayerCreated: (layers: any, sources: any) => void;
}

const LayerCreationPanel: React.FC<LayerCreationProps> = ({
  onLayerCreated,
}) => {
  const snackbar = useSnackbar();
  const { mapName,darkmode } = useContext(MapContext);
  const [formData, setFormData] = useState<LayerFormData>({
    layerType: "hexbin",
    selectionConfig: "{}",
    dataConfig: "{}",
    geometryConfig: "{}",
    styleConfig: "{}",
  });

  const [pendingRequests, setPendingRequests] = useState<number>(0);

  useEffect(() => {
    const formattedJson = (obj: any) => JSON.stringify(obj, null, 2);

    if(formData.layerType === "hexbin") {
      setFormData({
        layerType: formData.layerType,
        selectionConfig: formattedJson({
          map_name: mapName,
          selection_type: "all",
        }),
        dataConfig: formattedJson({
          resolution: 6,
          column: "year",
          operation: "mean",
        }),
        geometryConfig: formattedJson({}),
        styleConfig: formattedJson({
          tag: "hexbin",
          stroke_color: "#cccccc",
          stroke_width: 0.5,
        })
      });
    } else if (formData.layerType === "heatmap") {
      setFormData({
        layerType: formData.layerType,
        selectionConfig: formattedJson({
          map_name: mapName,
          selection_type: "all",
        }),
        dataConfig: formattedJson({
          resolution: 3,
          column: "year",
          operation: "mean",
        }),
        geometryConfig: formattedJson({}),
        styleConfig: formattedJson({
          tag: "grid",
          column: "year",
          min_value: 1950,
          max_value: 2024,
        }),
      });
    } else if (formData.layerType === "statistics") {
      setFormData({
        layerType: formData.layerType,
        selectionConfig: formattedJson({
          map_name: mapName,
          selection_type: "all",
        }),
        dataConfig: formattedJson({
          num_bins: 100,
          column: "year",
          operation: "mean",
        }),
        geometryConfig: formattedJson({
          contour: true,
          mask: true,
          interpolate: true,
          smooth_sigma: 0.1,
        }),
        styleConfig: formattedJson({
          tag: "grid",
        }),
      });
    } else if (formData.layerType === "cluster_labels") {
      setFormData({
        layerType: formData.layerType,
        selectionConfig: formattedJson({
          map_name: mapName,
          selection_type: "bounds",
          bounds: [-3, -3, 3, 3],
        }),
        dataConfig: formattedJson({
          method: "llm",
          context: {
            method: "sampled_titles",
            sampling: {
              method: "grid",
              num_bins: 5,
              k_per_grid: 1,
            },
          },
          prompting: {
            message_prompt_prefix:
              "identify a short descriptive label for each given cluster based on a few sampled papers from each cluster",
            system_prompt:
              "You are provided a number of cluster meta information in the format <cluster id=xyz>...</cluster>. Your task is to identify the main properties for each cluster.",
            function_calling_args: {
              label: {
                type: "string",
                description:
                  "A short label (max 3 words) which describes the cluster based on the main keywords extracted from the cluster. The label should be concise enough to be used as a title for the cluster, while being as specific as possible.",
              },
            },
            required_keys: ["label"],
            function_name: "summarize_cluster_information",
            function_description: "summarizes information from each cluster",
          },
          model: "gpt-4o-mini",
          batch_size: 100,
          max_concurrency: 3,
        }),
        geometryConfig: formattedJson({}),
        styleConfig: formattedJson({
          tag: "cluster_labels",
          labels: ["label"],
        }),
      });
    } else if (formData.layerType === "cluster") {
      setFormData({
        layerType: formData.layerType,
        selectionConfig: formattedJson({
          map_name: mapName,
          selection_type: "bounds",
          bounds: [-3, -3, 3, 3],
        }),
        styleConfig: formattedJson({
          lines: {
            tag: "clusters",
          },
          labels: { tag: "cluster_labels", labels: ["label"] },
        }),
        dataConfig: formattedJson({
          use_original_embeddings: true,
          clustering: {
            method: "kmeans",
            k: 10,
            dim_reduction_config: {
              method: "umap",
              n_components: 5,
            },
            spatial_adjustment_config: {
              num_bins: 100,
              frac: 0.5,
            },
          },
          labels: {
            method: "llm",
            context: {
              method: "sampled_titles",
              sampling: {
                method: "grid",
                num_bins: 5,
                k_per_grid: 1,
              },
            },
            prompting: {
              message_prompt_prefix:
                "identify a short descriptive label for each given cluster based on a few sampled papers from each cluster",
              system_prompt:
                "You are provided a number of cluster meta information in the format <cluster id=xyz>...</cluster>. Your task is to identify the main properties for each cluster.",
              function_calling_args: {
                label: {
                  type: "string",
                  description:
                    "A short label (max 3 words) which describes the cluster based on the main keywords extracted from the cluster. The label should be concise enough to be used as a title for the cluster, while being as specific as possible.",
                },
              },
              required_keys: ["label"],
              function_name: "summarize_cluster_information",
              function_description: "summarizes information from each cluster",
            },
            model: "gpt-4o-mini",
            batch_size: 100,
            max_concurrency: 3,
          },
        }),
        geometryConfig: formattedJson({
          lines: {
            method: "voronoi",
            alpha: 10,
            frac: 0.5,
            simplify: 0.0001,
          },
          labels: {},
        }),
      });
    }
  }, [formData.layerType]);

  const [isJsonValid, setIsJsonValid] = useState<{
    selectionConfig: boolean;
    dataConfig: boolean;
    geometryConfig: boolean;
    styleConfig: boolean;
  }>({
    selectionConfig: true,
    dataConfig: true,
    geometryConfig: true,
    styleConfig: true,
  });

  const validateJson = (jsonString: string): boolean => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  };

  const formatJson = (jsonString: string): string => {
    try {
      return JSON.stringify(JSON.parse(jsonString), null, 2);
    } catch {
      return jsonString;
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name as string]: value }));
    const isValid = validateJson(value as string);
    setIsJsonValid((prevValid) => ({
      ...prevValid,
      [name as string]: isValid,
    }));
    if (isValid) {
      setFormData((prevData) => ({
        ...prevData,
        [name as string]: formatJson(value as string),
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPendingRequests((prev) => prev + 1);

    try {
      const {
        layerType,
        selectionConfig,
        dataConfig,
        geometryConfig,
        styleConfig,
      } = formData;

      let { layers, sources }: { layers: any; sources: any } = {
        layers: [],
        sources: [],
      };

      if (layerType=="hexbin") {
        const resp = await createHexbinLayer(
          JSON.parse(selectionConfig),
          JSON.parse(dataConfig),
          JSON.parse(geometryConfig),
          JSON.parse(styleConfig),
          darkmode ? "dark" : "light"
        );
        layers = resp.layers;
        sources = resp.sources;
      }else if (layerType === "heatmap") {
        const resp = await createHeatmapLayer(
          JSON.parse(selectionConfig),
          JSON.parse(dataConfig),
          JSON.parse(geometryConfig),
          JSON.parse(styleConfig),
          darkmode ? "dark" : "light"
        );
        layers = resp.layers;
        sources = resp.sources;
      } else if (layerType === "statistics") {
        const resp = await createGridLayer(
          JSON.parse(selectionConfig),
          JSON.parse(dataConfig),
          JSON.parse(geometryConfig),
          JSON.parse(styleConfig),
          darkmode ? "dark" : "light"

        );
        layers = resp.layers;
        sources = resp.sources;
      } else if (layerType === "cluster_labels") {
        const resp = await createClusterLabelLayer(
          JSON.parse(selectionConfig),
          JSON.parse(dataConfig),
          JSON.parse(geometryConfig),
          JSON.parse(styleConfig),
          darkmode ? "dark" : "light"

        );
        layers = resp.layers;
        sources = resp.sources;
      } else if (layerType === "cluster") {
        const resp = await createClusterLayer(
          JSON.parse(selectionConfig),
          JSON.parse(dataConfig),
          JSON.parse(geometryConfig),
          JSON.parse(styleConfig),
          darkmode ? "dark" : "light"

        );
        layers = resp.layers;
        sources = resp.sources;
      } else {
        snackbar.showSnackbar("Layer type not supported", "error");
        return;
      }

      snackbar.showSnackbar("Layer created successfully", "success");
      onLayerCreated(layers, sources);
    } catch (e) {
      snackbar.showSnackbar("Error creating layer", "error");
    } finally {
      setPendingRequests((prev) => prev - 1);
    }
  };

  const jsonFields = [
    "selectionConfig",
    "dataConfig",
    "geometryConfig",
    "styleConfig",
  ];

  const allFieldsValid = jsonFields.every((field) =>
    validateJson(formData[field as keyof LayerFormData])
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      style={{ backgroundColor: "white", width: "400px", paddingTop: "40px" }}
    >
      <Typography variant="h6">Create New Layer</Typography>
      <Select
        name="layerType"
        value={formData.layerType}
        onChange={handleChange}
        label="Layer Type"
      >
        <MenuItem value="hexbin">Hexbin</MenuItem>
        <MenuItem value="statistics">Statistics</MenuItem>
        <MenuItem value="heatmap">Heatmap</MenuItem>
        <MenuItem value="cluster_labels">Labels</MenuItem>
        <MenuItem value="cluster">Cluster</MenuItem>
      </Select>
      {jsonFields.map((field, index) => (
        <TextField
          key={field}
          name={field}
          value={formData[field as keyof LayerFormData]}
          onChange={handleChange}
          label={
            ["selectionConfig", "dataConfig", "geometryConfig", "styleConfig"][
              index
            ]
          }
          error={!(isJsonValid as any)[field]}
          helperText={
            !(isJsonValid as any)[field as keyof LayerFormData]
              ? "Invalid JSON"
              : ""
          }
          multiline
          rows={8}
          InputProps={{
            style: {
              fontSize: "10px", // Super small font size
              lineHeight: "1.2", // Compact line spacing
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px", // Super small font size for the label
              lineHeight: "1.2", // Compact line spacing for the label
            },
          }}
          FormHelperTextProps={{
            style: {
              fontSize: "8px", // Smaller font size for helper text
              lineHeight: "1", // Compact line spacing for helper text
            },
          }}
        />
      ))}
      <Box display="flex" alignItems="center">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!allFieldsValid}
        >
          Submit
        </Button>
        {pendingRequests > 0 && (
          <Box display="flex" alignItems="center" ml={2}>
            <CircularProgress size={20} />
            <Typography variant="caption" ml={1}>
              {pendingRequests} pending...
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LayerCreationPanel;
