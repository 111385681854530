// src/hooks/useSearch.ts

import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../contexts/SnackbarContext";
import MapContext from "../contexts/MapContext";
import { performSearch } from "../searchUtils";

export function useSearch() {
  const [loading, setLoading] = useState(false);
  const { setSearchPaperResults, setSearchClusterResults, upsertLayer, viewport, zoom, mapName } = useContext(MapContext);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const search = async (searchQuery: string, bounds?: number[]) => {
    setLoading(true);
    await performSearch(
      mapName,
      searchQuery,
      viewport,
      zoom,
      setSearchPaperResults,
      setSearchClusterResults,
      upsertLayer,
      navigate,
      snackbar,
      bounds, //optional: search in given bounds
    );
    setLoading(false);
  };

  return { loading, search };
}
