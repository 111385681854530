import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Box, Paper, MenuItem, Select, FormControl } from '@mui/material';
import { promptFullText } from '../../requests';
import { useSnackbar } from '../../contexts/SnackbarContext';
import MarkdownEditor from '../utils/MarkdownEditor';

interface TextCompletionPromptingProps {
    selection: any;
    contexts: any;
}

const TextCompletionPrompting: React.FC<TextCompletionPromptingProps> = ({ selection, contexts }) => {
    const snackbar = useSnackbar();
    const [input, setInput] = useState<string>('Which short label describes this cluster best? The label should be in Google Maps Style (e.g. X&Y)');
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<string | null>(null);
    const [model, setModel] = useState<string>('gpt-4o-mini');

    const handleSend = async () => {
        setLoading(true);
        setResponse(null);

        try {
            const responseText = await promptFullText(selection, contexts, input, model);
            setResponse(responseText.text);
        } catch (error) {
            snackbar.showSnackbar("Error sending message", "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Box display="flex" alignItems="center" width="100%" gap={0}>
                <TextField
                    label="Enter your prompt"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <FormControl variant="outlined" style={{ minWidth: 50 }}>
                    <Select
                        value={model}
                        onChange={(e) => setModel(e.target.value as string)}
                        renderValue={() => "gpt"}
                    >
                        <MenuItem value="gpt-4o-mini">gpt4-o-mini</MenuItem>
                        <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
                        <MenuItem value="gpt-4o">gpt4-o</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Button variant="contained" color="primary" onClick={handleSend} disabled={loading}>
                Send
            </Button>
            {loading && <CircularProgress />}
            {response && (
                <Paper elevation={3} style={{ padding: '10px', marginTop: '10px',textAlign:'left' }}>
                    <MarkdownEditor content={response} />
                </Paper>
            )}
        </Box>
    );
};

export default TextCompletionPrompting;
