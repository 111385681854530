import axios from "axios";
import API_ROUTES from "./ApiRoutes";
import IViewport from "./interfaces/viewport";
import IPaperMeta from "./interfaces/paperMeta";
import IClusterDetails from "./interfaces/clusterDetails";
import ILandmarkedPapers from "./interfaces/landmarkedPapers";
import IClusterMeta from "./interfaces/clusterMeta";
import _ from "lodash";
import IClusterSearchResultMeta from "./interfaces/clusterSearchResultMeta";
import IPaperDetailsWithCoordinates, { IPaperDetails } from "./interfaces/paperDetails";
import IAuthorDetails from "./interfaces/authorDetails";
import { ILayer } from "./interfaces/mapConfig";
import { ISourceConfig } from "./contexts/MapContext";
import api from "./api";


export async function fetchMapConfig(mapName: string) {
  const response = await api.get(API_ROUTES.getMapConfig(mapName));
  
  return response.data;
}

export async function updateMapSourceJSON(mapName:string, sourceJson:any):Promise<any>{
  const response = await api.post(API_ROUTES.updateMapSourceJSON(mapName), {
    ...sourceJson
  });
  return response.data;
}

export async function postViewportBookmark(mapName: string, viewport: any) {
  const response = await api.post(API_ROUTES.postViewportBookmark(), {
    mapName: mapName,
    viewport: viewport,
  });
  return response.data;
}

export async function fetchClusterDetails(
  mapName: string,
  clusterId: number
): Promise<IClusterDetails> {
  const response = await api.get(
    API_ROUTES.fetchClusterDetails(mapName, clusterId)
  );

  //if response.data is not an object throw error
  if (typeof response.data !== "object") {
    throw new Error("Invalid response");
  }
  return response.data;
}

export async function generateClusterDescription(
  mapName: string,
  clusterId: number,
  contexType: string
): Promise<IClusterDetails> {
  const response = await api.post(
    API_ROUTES.postGenerateClusterDescription(mapName, clusterId),
    {
      context_type: contexType,
    }
  );
  return response.data;
}

export async function generateClusterPicture(
  mapName: string,
  clusterId: number,
  contexType: string
): Promise<IClusterDetails> {
  const response = await api.post(
    API_ROUTES.postGenerateClusterPicture(mapName, clusterId),
    {
      context_type: contexType,
    }
  );
  return response.data;
}

export async function fetchPapersInViewport(
  mapName: string,
  viewport: IViewport
): Promise<IPaperMeta[]> {
  const response = await api.get(
    API_ROUTES.getPapersInViewport(mapName, viewport)
  );
  //check if response is list
  return response.data;
}

export async function fetchClustersInViewport(
  mapName: string,
  viewport: IViewport
): Promise<IClusterMeta[]> {
  const response = await api.get(
    API_ROUTES.getClustersInViewport(mapName, viewport)
  );
  //check if response is list
  return response.data;
}

export async function fetchSearchResults(
  mapName: string,
  query: string,
  bounds: number[]| null=null,
  limit: number = 1000
): Promise<{
  areas: any;
  landmarks: any;
  papers: IPaperMeta[];
  clusterResults: IClusterSearchResultMeta[];
  heatmap:{
    source: any;
    layer:any;
  }
}> {
  const response = await api.get(
    API_ROUTES.searchPapers(mapName, query, limit,bounds)
  );
  const areas = response.data.areas;
  const landmarks = response.data.landmarks;
  const papers = response.data.papers;
  const cluster_meta_by_cluster_id =
    response.data.cluster_meta_by_cluster_id || {};

  const clusterResults = response.data.cluster_search_results || [];
  if (cluster_meta_by_cluster_id) {
    for (const paper of papers) {
      paper.cluster_metas = _.get(paper, "cluster_metas", []).map(
        (cluster_id: number) => {
          return cluster_meta_by_cluster_id[cluster_id];
        }
      );
    }
  }
  const heatmap=response.data.heatmap;

  return { areas, landmarks, papers, clusterResults,heatmap };
}

interface LayerSource{
  layer:any,
  source:any
}

export async function fetchAuthorDetails(
  authorId:number
):Promise<IAuthorDetails>{
  const response = await api.get(API_ROUTES.fetchAuthorDetails(authorId));
  return response.data;
}


export async function fetchAuthorPapers(
  mapName:string,
  authorId:number
):Promise<{layers:ILayer[], sources:ISourceConfig[],papers:IPaperMeta[]}>{
  const response = await api.get(API_ROUTES.fetchAuthorPapers(mapName, authorId));
  return response.data;
}

export async function fetchAuthorCitations(
  mapName:string,
  authorId:number
):Promise<{layers:ILayer[], sources:ISourceConfig[],papers:IPaperMeta[]}>{
  const response = await api.get(API_ROUTES.fetchAuthorCitations(mapName, authorId));
  return response.data;
}

export async function fetchAuthorReferences(
  mapName:string,
  authorId:number
):Promise<{layers:ILayer[], sources:ISourceConfig[],papers:IPaperMeta[]}>{
  const response = await api.get(API_ROUTES.fetchAuthorReferences(mapName, authorId));
  return response.data;
}

export async function createContext(
  selection:any,
  contexts:any
):Promise<string>{
  const response = await api.post(
    API_ROUTES.postCreateContext(),
    {
      selection,
      contexts
    }
  );
  return response.data;
}

export async function promptFunctionCalling(
  selection:any,
  contexts:any,
  prompting:any,
  model:string = "gpt-4o-mini"
):Promise<any>{
  const response = await api.post(API_ROUTES.postFunctionCallingPrompting(), {
    selection,
    contexts,
    prompting,
    model
  });
  return response.data;
}
export async function fetchAutocompleteSuggestions(mapName:string, query:string) :Promise<any>{
  const response = await api.get(API_ROUTES.getAutocompleteSuggestions(mapName, query));
  return response.data;
}

export async function createHeatmapLayer(selection:any, data_config:any, geometry_config:any, style_config:any, theme: "light" | "dark"):Promise<any>{
  const response = await api.post(API_ROUTES.postCreateHeatmapLayer(), {
    selection,
    data_config,
    geometry_config,
    style_config,
    theme
  });
  return response.data;

}


export async function createHexbinLayer(selection:any, data_config:any, geometry_config:any, style_config:any, theme: "light" | "dark"):Promise<any>{
  const response = await api.post(API_ROUTES.postCreateHexbinLayer(), {
    selection,
    data_config,
    geometry_config,
    style_config,
    theme
  });
  return response.data;

}

export async function createGridLayer(selection:any, data_config:any,geometry_config:any, style_config:any,theme:"light" | "dark"):Promise<any>{
  const response = await api.post(API_ROUTES.postCreateGridLayer(), {
    selection,
    data_config,
    geometry_config,
    style_config,
    theme
  });
  return response.data;
}

export async function createClusterLabelLayer(selection:any, data_config:any, geometry_config:any, style_config:any,theme:"light" | "dark"):Promise<any>{
  const response = await api.post(API_ROUTES.postCreateClusterLabelsLayer(), {
    selection,
    data_config,
    geometry_config,
    style_config,
    theme
  });
  return response.data;
}

export async function createClusterLayer(selection:any, data_config:any, geometry_config:any, style_config:any, theme: "light" | "dark"):Promise<any>{
  const response = await api.post(API_ROUTES.postCreateClusterLayer(), {
    selection,
    data_config,
    geometry_config,
    style_config,
    theme
  });
  return response.data;
}

export async function promptFullText(
  selection: any,
  contexts: any,
  prompt: string,
  model: string = "gpt-4o-mini"
): Promise<{text:string}> {
  const response = await api.post(API_ROUTES.postFullTextPrompting(), {
    selection,
    contexts,
    prompt,
    model
  });
  return response.data;
}

export async function fetchPaperDetailsWithCoordinates(
  mapName: string,
  corpusid: number,
  generatePicture: boolean = false
): Promise<IPaperDetailsWithCoordinates> {
  const response = await api.get(
    API_ROUTES.getPaperDetailsWithCoordinates(mapName, corpusid, generatePicture)
  );
  return response.data;
}

export async function fetchPaperDetails(
  mapName:string,
  corpusid:number
):Promise<IPaperDetails>{
  //const response = await api.get(API_ROUTES.getPaperDetailsWithCoordinates(mapName, corpusid,false));
  const response = await api.get(API_ROUTES.getPaperDetails(corpusid));

  return response.data;

}

export async function fetchPaperReferences(
  mapName: string,
  corpusid: number
): Promise<{papers:IPaperMeta[], sources:ISourceConfig[],layers:ILayer[]}> {
  const response = await api.get(
    API_ROUTES.getPaperReferences(mapName, corpusid)
  );
  return response.data;
}

export async function fetchPaperCitations(
  mapName: string,
  corpusid: number
): Promise<{papers:IPaperMeta[], sources:ISourceConfig[],layers:ILayer[]}> {
  const response = await api.get(
    API_ROUTES.getPaperCitations(mapName, corpusid)
  );
  return response.data;
}

export async function fetchPaperNearestNeighbors(
  mapName: string,
  corpusid: number
): Promise<{papers:IPaperMeta[], sources:ISourceConfig[],layers:ILayer[]}> {
  const response = await api.get(
    API_ROUTES.getPaperNearestNeighbors(mapName, corpusid)
  );
  return response.data;
}
