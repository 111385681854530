import React, { useState } from 'react';
import defaultContexts, {Context} from './contextConfigs';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

interface ContextEditorProps {
  initialContexts: Context[];
  onUpdateContexts: (contexts: Context[]) => void;
}


const ContextEditor: React.FC<ContextEditorProps> = ({ initialContexts = defaultContexts, onUpdateContexts }) => {
  const [contexts, setContexts] = useState<Context[]>(initialContexts);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editJson, setEditJson] = useState<string>('');

  const handleAddContext = (method: string) => {
    const newContext = defaultContexts.find((context) => context.method === method);
    if (newContext) {
      const newContexts = [...contexts, { ...newContext }];
      setContexts(newContexts);
      onUpdateContexts(newContexts);
    }
  };

  const handleRemoveContext = (index: number) => {
    const newContexts = contexts.filter((_, i) => i !== index);
    setContexts(newContexts);
    onUpdateContexts(newContexts);
  };

  const handleEditContext = (index: number) => {
    setEditIndex(index);
    setEditJson(JSON.stringify(contexts[index], null, 2));
  };

  const handleSaveEdit = () => {
    try {
      const newContext: Context = JSON.parse(editJson);
      const newContexts = contexts.map((context, i) =>
        i === editIndex ? newContext : context
      );
      setContexts(newContexts);
      onUpdateContexts(newContexts);
      setEditIndex(null);
      setEditJson('');
    } catch (error) {
      alert('Invalid JSON');
    }
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditJson('');
  };

  return (
    <Box>
      {contexts.map((context, index) => (
        <div key={index} style={{border:'1px solid lightgrey',padding:'15px'}}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9}>
              <Typography>{context.method}</Typography>
              <Typography variant="body2" noWrap>
                {JSON.stringify(context).slice(0, 50)}...
              </Typography>
            </Grid>
            <Grid item xs={3} container justifyContent="flex-end">
              <IconButton onClick={() => handleEditContext(index)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleRemoveContext(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ))}
      <Box mt={2} display="flex" justifyContent="space-around">
        <Button
        size="small" 
          variant="outlined"
          color="primary"
          onClick={() => handleAddContext('sampled_titles')}
        >
          Add Sampled Titles
        </Button>
        <Button size="small" variant="outlined" color="primary" onClick={() => handleAddContext('tf')}>
          Add TF
        </Button>
        <Button size="small" variant="outlined" color="primary" onClick={() => handleAddContext('top_titles')}>
          Add Top Titles
        </Button>
      </Box>

      <Dialog open={editIndex !== null} onClose={handleCancelEdit}>
        <DialogTitle>Edit Context</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rows={10}
            variant="outlined"
            fullWidth
            value={editJson}
            onChange={(e) => setEditJson(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContextEditor;
