import IViewport from "./interfaces/viewport";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://95.217.150.234:5000';
console.log(API_BASE_URL);
console.log(process.env.REACT_APP_TEST_VAR);
console.log(process.env.TEST_VAR);

const API_ROUTES={
    searchPapers: (mapName: string, query:string, limit:number, bounds?:number[] | null) => `${API_BASE_URL}/search/${mapName}?q=${query}&limit=${limit}${bounds? "&bounds="+bounds.join(',') : ""}`,
    searchAuthors:(query:string) => ``,
    fetchAuthorDetails:(authorId:number) => `${API_BASE_URL}/authors/${authorId}/details`,
    fetchAuthorPapers:(mapName:string, authorId:number) => `${API_BASE_URL}/authors/${mapName}/${authorId}/papers`,
    fetchAuthorCitations:(mapName:string, authorId:number) => `${API_BASE_URL}/authors/${mapName}/${authorId}/citations`,
    fetchAuthorReferences:(mapName:string, authorId:number) => `${API_BASE_URL}/authors/${mapName}/${authorId}/references`,
    getMapConfig: (mapName: string) => `${API_BASE_URL}/maps/${mapName}`,
    getLayer:(layerId:string) => `TODO`,
    getPaperDetailsWithCoordinates:(mapName:string,corpusid:number,generatePicture:boolean) => `${API_BASE_URL}/papers/${mapName}/${corpusid}?generate_picture=${generatePicture}`,
    getPaperDetails:(corpusid:number) => `${API_BASE_URL}/papers/${corpusid}`,
    getPaperCitations:(mapName:string,corpusid:number) => `${API_BASE_URL}/papers/${mapName}/${corpusid}/citations`,
    getPaperReferences:(mapName:string,corpusid:number) => `${API_BASE_URL}/papers/${mapName}/${corpusid}/references`,
    getPaperNearestNeighbors:(mapName:string,corpusid:number) => `${API_BASE_URL}/papers/${mapName}/${corpusid}/nearest_neighbors`,
    postViewportBookmark: () => `${API_BASE_URL}/bookmarks/viewport`,
    fetchClusterDetails: (mapName:string,clusterId:number) => `${API_BASE_URL}/clusters/${mapName}/${clusterId}`,
    postCreateContext: () => `${API_BASE_URL}/prompting/context`,
    postGenerateClusterDescription: (mapName: string, clusterId:number) => `${API_BASE_URL}/clusters/${mapName}/${clusterId}/generate_description`,
    postGenerateClusterPicture: (mapName: string, clusterId:number) => `${API_BASE_URL}/clusters/${mapName}/${clusterId}/generate_picture`,
    getPapersInViewport: (mapName: string, viewport: IViewport) => {
        // Extract the coordinates from the viewport object
        const minLng = viewport.sw.lng;
        const minLat = viewport.sw.lat;
        const maxLng = viewport.ne.lng;
        const maxLat = viewport.ne.lat;
    
        // Construct the URL with the min/max syntax
        return `${API_BASE_URL}/papers/viewport/${mapName}?minLng=${minLng}&minLat=${minLat}&maxLng=${maxLng}&maxLat=${maxLat}`;
    },
    getClustersInViewport: (mapName: string, viewport: IViewport) => 
        `${API_BASE_URL}/clusters/${mapName}/viewport?minLng=${viewport.sw.lng}&minLat=${viewport.sw.lat}&maxLng=${viewport.ne.lng}&maxLat=${viewport.ne.lat}`,
    getViewportBookmarks: (mapName:string) => `${API_BASE_URL}/bookmarks/viewport/${mapName}`,
    postFullTextPrompting: () => `${API_BASE_URL}/prompting/execute_context/text`,
    postFunctionCallingPrompting: () => `${API_BASE_URL}/prompting/execute_context/fc`,
    postCreateGridLayer: ()=>`${API_BASE_URL}/layers/statistics`,
    postCreateHeatmapLayer: ()=>`${API_BASE_URL}/layers/heatmap`,
    postCreateHexbinLayer: ()=>`${API_BASE_URL}/layers/hexbin`,
    postCreateClusterLabelsLayer: ()=>`${API_BASE_URL}/layers/cluster_labels`,
    postCreateClusterLayer: ()=>`${API_BASE_URL}/layers/cluster`,
    updateMapSourceJSON:(mapName:string)=>`${API_BASE_URL}/maps/${mapName}/source_json`,
    getAutocompleteSuggestions:(mapName:string, query:string)=>`${API_BASE_URL}/autocomplete/${mapName}?q=${query}`,

}

export default API_ROUTES;