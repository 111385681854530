import "./ClusterPreviewBatch.scss";
import IClusterMeta, { IClusterMetaSmall } from "../../interfaces/clusterMeta";
import { useContext } from "react";
import MapContext from "../../contexts/MapContext";
import { computeBoundingBox } from "../../geometryUtils";
import ClusterPreviewBatch from "./ClusterPreviewBatch";

export default function ClusterPreviewBatchList({
  clusterMetas,
  relation
}: {
  clusterMetas: IClusterMetaSmall[];
  relation?: "parent" | "child";
}) {
  return (<div style={{ }}>
    {clusterMetas.map((clusterMeta: IClusterMetaSmall, index: number) => {
      return <ClusterPreviewBatch relation={relation} clusterMeta={clusterMeta} key={index} />;
    })}
  </div>);
}
