import React, { useContext, useState, KeyboardEvent, useEffect } from "react";
import { Box, TextField, IconButton, CircularProgress, Typography, Stack } from "@mui/material";
import { useSearch } from "../hooks/useSearch";
import MapContext from "../contexts/MapContext";
import SearchIcon from '@mui/icons-material/Search';

interface AreaSearchProps {}

const AreaSearch: React.FC<AreaSearchProps> = () => {
  const { viewport, searchQuery } = useContext(MapContext);
  const [areaSearchQuery, setAreaSearchQuery] = useState("");
  const [showInput, setShowInput] = useState(true);

  const { loading, search } = useSearch();

  useEffect(() => {
    if (searchQuery) {
      setAreaSearchQuery(searchQuery);
      setShowInput(false);
    }
  }, [searchQuery]);

  const handleSearchClick = () => {
    search(areaSearchQuery, [
      viewport.sw.lng,
      viewport.sw.lat,
      viewport.ne.lng,
      viewport.ne.lat,
    ]);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleAreaQueryClick = () => {
    setShowInput(true);
  };

  return (
    <Box 
      display="flex" 
      alignItems="center"
      sx={{
        backgroundColor: '#fff',
        borderRadius: '24px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
        padding: '8px 16px',
        minHeight: '40px',
        minWidth: '250px'
      }}
    >
      <IconButton 
        onClick={handleSearchClick} 
        disabled={loading}
        sx={{ 
          padding: '8px',
          marginRight: '4px',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' }
        }}
      >
        {loading ? (
          <CircularProgress size={22} /> 
        ) : (
          <SearchIcon sx={{ fontSize: 22, color: '#5B5B5B' }} />
        )}
      </IconButton>

      <Stack spacing={0}>
        {showInput ? (
          <TextField
            variant="standard"
            placeholder="Search in this area"
            value={areaSearchQuery}
            onChange={(e) => setAreaSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              '& input': {
                padding: '4px 0',
                fontSize: '15px',
                fontWeight: 500
              }
            }}
          />
        ) : (
          <Typography
            variant="body1"
            onClick={handleAreaQueryClick}
            sx={{
              fontSize: '15px',
              fontWeight: 500,
              color: '#1a73e8',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            "{areaSearchQuery}"
          </Typography>
        )}
        {!showInput && (
          <Typography
            variant="caption"
            sx={{
              color: '#70757a',
              fontSize: '12px',
              marginTop: '-2px'
            }}
          >
            Search within visible region
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default AreaSearch;
