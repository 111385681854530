import { CircularProgress } from "@mui/material";
import ScholarmapsIcon from "./ScholarmapsIcon";

export default function LoadingScreen({}){
    return <div style={{
        width:"100vw",
        height:"100vh",
        backgroundColor:"#ffffff",
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        position:"fixed",
        alignContent:'center',
        gap:"10px",
        top:"0",
        left:"0",
        zIndex:100,
    }}>
      <ScholarmapsIcon/><CircularProgress size={30}/>

    </div>
}