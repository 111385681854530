// src/utils/searchUtils.ts

import { LayerProps } from "react-map-gl";
import { createLandmarkLayerConfig, createLandmarkLayerProps } from "./createLandmarkLayer";
import { fetchSearchResults } from "./requests";
import { ISourceConfig } from "./contexts/MapContext";

export async function performSearch(
  mapName: string,
  searchQuery: string,
  viewport: any,
  zoom: number,
  setSearchPaperResults: (results: any) => void,
  setSearchClusterResults: (results: any) => void,
  upsertLayer: (id: string, layer: any, source: ISourceConfig, beforeId?: string) => void,
  navigate: (path: string) => void,
  snackbar: any,
  bounds?: number[] | null 
) {
  try {
    const searchFeatures = await fetchSearchResults(mapName, searchQuery, bounds, 1000);
    let layerConfigWithoutTitles: LayerProps = createLandmarkLayerProps(
      createLandmarkLayerConfig(searchFeatures.landmarks),
      true,
      true
    );
    layerConfigWithoutTitles.maxzoom = 8;
    layerConfigWithoutTitles.id = "search_landmarks_without_titles";
    layerConfigWithoutTitles.source = "search_landmarks_without_titles";

    let layerConfigWithTitles: LayerProps = createLandmarkLayerProps(
      createLandmarkLayerConfig(searchFeatures.landmarks),
      false,
      true
    );
    layerConfigWithTitles.minzoom = 8;
    layerConfigWithTitles.layout!["icon-ignore-placement"] = false;
    layerConfigWithTitles.id = "search_landmarks_with_titles";
    layerConfigWithTitles.source = "search_landmarks_with_titles";

    const sourceWithTitles: ISourceConfig = {
      data: searchFeatures.landmarks.data,
      type: "geojson",
    };
    const sourceWithoutTitles: ISourceConfig = {
      data: searchFeatures.landmarks.data,
      type: "geojson",
    };

    upsertLayer(layerConfigWithTitles.id, layerConfigWithTitles as any, sourceWithTitles, "cluster2_labels");
    upsertLayer(layerConfigWithoutTitles.id, layerConfigWithoutTitles as any, sourceWithoutTitles, "cluster2_labels");
    upsertLayer("search_heatmap", searchFeatures.heatmap.layer, searchFeatures.heatmap.source,"papers");


    setSearchPaperResults(searchFeatures.papers);
    setSearchClusterResults(searchFeatures.clusterResults);

    const centerLng = ((viewport.ne.lng + viewport.sw.lng) / 2).toFixed(7);
    const centerLat = ((viewport.ne.lat + viewport.sw.lat) / 2).toFixed(7);
    const newPath = `/map/${mapName}/search/${encodeURIComponent(searchQuery)}/coordinates/${centerLat},${centerLng},${zoom}z`;

    //navigate(newPath);
  } catch (e) {
    snackbar.showSnackbar("Error searching", "error");
    console.error(e);
  }
}
