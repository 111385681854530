import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
import IPaperMeta from "../../interfaces/paperMeta";
import numberWithCommas from "../../utils";
import CitationsIcon from "./CitationIcon";

export default function CitationCountDisplay({
  citationCount,
  color = "#b85900",
  showBorder = true,
}: {
    color?: string;
    showBorder?:boolean
  citationCount: number;
}) {
    return (
        <div
          style={{
            borderRadius: 10,
            border: showBorder ? "0px solid #d9dadb" : "1px solid rgba(0,0,0,0.)",
            padding: showBorder ? "0px 8px" : 0,
            color: color, //#1890ff
          }}
          className={"citatationCounter"}
        >
          <CitationsIcon color={color}></CitationsIcon>
          <span style={{paddingLeft:5}}>
            {numberWithCommas(citationCount || 0 ) + " citations" ||
              "could not find citations"}
          </span>
        </div>
      );
}
