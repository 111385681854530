import { Button, CircularProgress, IconButton } from "@mui/material";
import { fetchPaperDetailsWithCoordinates, generateClusterPicture } from "../../requests";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useContext, useState } from "react";
import IClusterDetails from "../../interfaces/clusterDetails";
import MapContext from "../../contexts/MapContext";
import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
export default function PaperAIButton({
  mapName,
  selectedCorpusId,
}: {
  mapName:string,
  selectedCorpusId: number;
}) {
  const [isGenerating, setIsGenerating] = useState(false);
  const { selectedPaperDetails, setSelectedPaperDetails } =
    useContext(MapContext);

    const generatePicture=()=>{
      setIsGenerating(true);
      fetchPaperDetailsWithCoordinates(mapName, selectedPaperDetails!.id,true).then((paperDetails:IPaperDetailsWithCoordinates)=>{
          setSelectedPaperDetails(paperDetails);
      }).catch(console.error).finally(()=>setIsGenerating(false));
  }

  return (
    <IconButton
      style={{ paddingLeft:'5px',backgroundColor: "white",fontSize:'15px' }}
      size="small"
      disabled={isGenerating}
      onClick={generatePicture}
      className="btn-generate"
    >
      {isGenerating ? <CircularProgress style={{fontSize:'20px'}} size={15} /> : <AutoAwesomeIcon style={{fontSize:'20px'}} />}
    </IconButton>
  );
}
