import * as d3 from "d3-scale";
import { interpolateReds } from "d3-scale-chromatic";
import { LayerProps } from "react-map-gl";
import { GOOGLE_MAPS_COLORS, GOOGLE_MAPS_COLORS_DETAILS } from "./colors";
import IPolygonLayer from "./interfaces/polygonLayer";
const DEFAULT_SOURCE = "default";

export function createPolygonConfig(layerConfig: any): IPolygonLayer {
  let polygonLayer = layerConfig as IPolygonLayer;
  switch (layerConfig.tag) {
    case "hovered_cluster":
      polygonLayer.fill_type = "given";
      polygonLayer.fill_color = "#ffff00";
      polygonLayer.stroke = "#000000";
      polygonLayer.opacity = 0.5;
      break;
    case "selected_cluster":
      polygonLayer.fill_type = "given";
      polygonLayer.fill_color = "#ff0000";
      polygonLayer.opacity = 0.1;
      polygonLayer.stroke = "#000000";
      break;
    case "bounds":
      polygonLayer.fill_type = "given";
      polygonLayer.fill_color = "#00ff00";
      polygonLayer.opacity = 0.1;
      polygonLayer.stroke = "#000000";
      break;
    case "clusters":
      //polygonLayer.fill_color="rgba(240,240,240,0.8)";
      polygonLayer.hover_color = "rgba(255,0,0,0.1)";
      polygonLayer.selected_color = "rgba(100,0,0,0.2)";
      polygonLayer.stroke = null;

      polygonLayer.fill_type = "step_value";
      polygonLayer.min_fill_value = 2000;
      polygonLayer.max_fill_value = 2025;
      polygonLayer.fill_property = "mean_year";

      //
      //polygonLayer.fill_type = "interpolate_value";
      //polygonLayer.fill_property = "num_elements";
      if (layerConfig.layer_name === "cluster_area_0") {
        polygonLayer.min_fill_value = 100000;
        polygonLayer.max_fill_value =500000;
      } else {
        polygonLayer.min_fill_value = 1000;
        polygonLayer.max_fill_value = 100000;
      }
      //

      polygonLayer.fill_steps = [
        [1970, "rgba(0,0,0,0.0)"], // Assuming no data or irrelevant data before 1970
        [1980, GOOGLE_MAPS_COLORS_DETAILS.desert0], // Color from 1970 to 1980
        [1990, GOOGLE_MAPS_COLORS_DETAILS.desert1], // Color from 1980 to 1990
        [2000, GOOGLE_MAPS_COLORS_DETAILS.desert2], // Color from 1990 to 2000
        [2010, GOOGLE_MAPS_COLORS_DETAILS.orange0], // Color from 2000 to 2010
        [2015, GOOGLE_MAPS_COLORS_DETAILS.orange1], // Color from 2010 to 2015
        [2020, GOOGLE_MAPS_COLORS_DETAILS.green2], // Color from 2015 to 2020
        [2025, GOOGLE_MAPS_COLORS_DETAILS.green3], // Color from 2020 to 2025
        // Assuming the latest color extends beyond 2025 until any further specified interval
      ];

      break;
    case "papers":
      polygonLayer.fill_type = "given";
      polygonLayer.min_fill_value = 0;
      polygonLayer.max_fill_value = 100;
      polygonLayer.fill_color = GOOGLE_MAPS_COLORS.building;
      //polygonLayer.min_fill_value=0x00f000;
      //polygonLayer.max_fill_value=0xff0000;

      //polygonLayer.fill_color = GOOGLE_MAPS_COLORS.building;
      polygonLayer.stroke = GOOGLE_MAPS_COLORS.buildingOutline;
      polygonLayer.hoverable = true;
      polygonLayer.min_hover_zoom = 9;
      break;
    case "citations":
      polygonLayer.fill_type = "interpolate_value";
      polygonLayer.fill_property = "grid_value";

      if (
        polygonLayer.args &&
        polygonLayer.args.min !== undefined &&
        polygonLayer.args.max !== undefined
      ) {
        polygonLayer.min_fill_value = polygonLayer.args.min;
        polygonLayer.max_fill_value = polygonLayer.args.max;
      } else {
        console.error("layer needs min and max args");
      }
      polygonLayer.opacity = 0.01;
      break;
    case "search":
      polygonLayer.fill_type = "given";
      polygonLayer.fill_color = GOOGLE_MAPS_COLORS.searchLandmarks;
      polygonLayer.stroke = GOOGLE_MAPS_COLORS.buildingOutline;
      polygonLayer.opacity = 0.3;
      break;
    case "sea":
      polygonLayer.fill_type = "given";
      polygonLayer.fill_color = GOOGLE_MAPS_COLORS.water;
      break;
    case "fos_polygons":
      polygonLayer.fill_type = "given";
      polygonLayer.fill_color = GOOGLE_MAPS_COLORS.urbanArea;
      break;
    case "hexbin":
    case "areas":
      polygonLayer.fill_type = "step_value";
      //polygonLayer.fill_type = "interpolate_value";
      //polygonLayer.stroke="#ccc";
      //polygonLayer.stroke_width=0.1;
      polygonLayer.min_fill_value = -1;
      polygonLayer.max_fill_value = 7;

      polygonLayer.opacity = 1;
      polygonLayer.fill_property = "grid_value";

      polygonLayer.fill_steps = [
        [-1, "rgba(0,0,0,0.0)"],
        [0, GOOGLE_MAPS_COLORS_DETAILS.desert0],
        [1, GOOGLE_MAPS_COLORS_DETAILS.desert1],
        [2, GOOGLE_MAPS_COLORS_DETAILS.desert2],
        [3, GOOGLE_MAPS_COLORS_DETAILS.orange0],
        [4, GOOGLE_MAPS_COLORS_DETAILS.orange1],
        [5, GOOGLE_MAPS_COLORS_DETAILS.green2],
        [6, GOOGLE_MAPS_COLORS_DETAILS.green3],
        [7, GOOGLE_MAPS_COLORS_DETAILS.green4],
      ];
      break;
    case "cluster_areas":
      polygonLayer.fill_type = "given";
      polygonLayer.fill_color = "#ff0000";
      polygonLayer.opacity = 0.2;
      break;
    default:
      polygonLayer.fill_color = "#ffffff";
      polygonLayer.fill_type = "given";
      polygonLayer.opacity = 0.5;
      break;
  }

  return polygonLayer;
}
export function createPolygonLayerProps(
  polygonLayer: IPolygonLayer,
  isGeojsonSource: boolean=false
): LayerProps {
  
  // Default fill color expression based on the fill_type
  let fillColorExpression: any = "#ff00ff";

  switch (polygonLayer.fill_type) {
    case "given":
      fillColorExpression = polygonLayer.fill_color;
      break;
    case "interpolate_value":
      if (
        polygonLayer.fill_property &&
        polygonLayer.min_fill_value !== undefined &&
        polygonLayer.max_fill_value !== undefined
      ) {
        const colorScale = d3
          .scaleSequential(interpolateReds)
          .domain([polygonLayer.min_fill_value, polygonLayer.max_fill_value]);
        fillColorExpression = [
          "interpolate",
          ["linear"],
          ["to-number", ["get", polygonLayer.fill_property]],
          polygonLayer.min_fill_value,
          colorScale(polygonLayer.min_fill_value),
          polygonLayer.max_fill_value,
          colorScale(polygonLayer.max_fill_value),
        ];
      } else {
        console.error(
          "fill_property, min_fill_value and max_fill_value must be set for interpolate_value"
        );
      }
      break;
    case "step_value":
      if (
        polygonLayer.fill_property &&
        polygonLayer.fill_steps &&
        polygonLayer.fill_steps.length > 0
      ) {
        // Begin with the lowest value as the default color before any steps
        fillColorExpression = [
          "step",
          ["get", polygonLayer.fill_property],
          polygonLayer.fill_steps[0][1], // Default color (should be the color for the lowest interval)
        ];

        // Add each step to the expression
        for (let i = 1; i < polygonLayer.fill_steps.length; i++) {
          fillColorExpression.push(polygonLayer.fill_steps[i][0]); // The threshold value
          fillColorExpression.push(polygonLayer.fill_steps[i][1]); // The color to use above this threshold
        }
      }
      break;
    case "random":
    default:
      fillColorExpression = "#ff00aa"; // Default or random color logic
      break;
  }

  // Conditional expression to replace the fill color if hovered or selected
  if (polygonLayer.hover_color || polygonLayer.selected_color) {
    const hoverExpression: any[] = ["case"];
    if (polygonLayer.selected_color) {
      hoverExpression.push(
        ["boolean", ["feature-state", "select"], false],
        polygonLayer.selected_color
      );
    }
    if (polygonLayer.hover_color) {
      hoverExpression.push(
        ["boolean", ["feature-state", "hover"], false],
        polygonLayer.hover_color
      );
    }
    hoverExpression.push(fillColorExpression); // Default to the regular fill color
    fillColorExpression = hoverExpression;
  }

  // Construct the paint object
  const paint: any = {
    "fill-color": fillColorExpression,
    "fill-opacity": polygonLayer.opacity ?? 1,
  };

  // Add the fill-outline color if stroke is specified
  if (polygonLayer.stroke) {
    paint["fill-outline-color"] = polygonLayer.stroke;
  }

  // Assemble the layer properties
  const layerProps: LayerProps = {
    id: polygonLayer.layer_name,
    type: "fill",
    paint: paint,
    layout: {
      "fill-sort-key": ["-", ["get", "area"]],
    },
    minzoom: polygonLayer.min_zoom,
    maxzoom: polygonLayer.max_zoom,
    source: polygonLayer.source || DEFAULT_SOURCE
  };

  if(!isGeojsonSource){
    layerProps['source-layer']= polygonLayer.layer_name;
  }
  // Add additional properties based on your IPolygonLayer structure and requirements
  // Note: You might need to adjust or add props based on the specifics of your implementation
  return layerProps;
}
