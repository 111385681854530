import { IconButton, Skeleton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { promptFullText } from "../../requests";
import { useSnackbar } from "../../contexts/SnackbarContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MarkdownEditor from "../utils/MarkdownEditor";

export default function AIAnswer({
  autoGenerate = false,
  selection,
  contexts,
  answer,
  prompt,
  model = "gpt-4o-mini",
  allowRegenerate = false,
  markdownStyles = {}, // Accept markdownStyles as a prop with default empty object
  numSkeletonRows=2
}: {
  autoGenerate?: boolean;
  selection: any;
  contexts: any;
  answer?: string | null;
  prompt: any;
  model?: string;
  allowRegenerate?: boolean;
  markdownStyles?: any; // Define the type for markdownStyles
  numSkeletonRows?: number;
}) {
  const snackbar = useSnackbar();
  const [isGenerating, setIsGenerating] = useState(false);
  const [responseValue, setResponseValue] = useState<string | null>(answer!);
  const hasAutoGenerated = useRef(false);

  const generateAnswer = async () => {
    setIsGenerating(true);
    setResponseValue(null);
    try {
      const response = await promptFullText(selection, contexts, prompt, model);
      setResponseValue(response.text);
    } catch (error) {
      snackbar.showSnackbar("Error sending message", "error");
    } finally {
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    if (
      autoGenerate &&
      !responseValue &&
      !isGenerating &&
      !hasAutoGenerated.current
    ) {
      hasAutoGenerated.current = true;
      generateAnswer();
    }
  }, [autoGenerate, responseValue, isGenerating, selection]);

  return (
    <div className="aiAnswer">
      {responseValue && (
        <>
          <div className="answer">
            {/* Pass the markdownStyles prop to the MarkdownEditor */}
            <MarkdownEditor content={responseValue} {...markdownStyles} />
          </div>
          {allowRegenerate && (
            <IconButton
              size={"small"}
              style={{ fontSize: "12px" }}
              onClick={generateAnswer}
            >
              Regenerate <AutorenewIcon style={{ fontSize: "20px" }} />
            </IconButton>
          )}
        </>
      )}

      {isGenerating && (
        <div>
            {Array.from({ length: numSkeletonRows }).map((_, index) => (
                    <Skeleton key={index} />
                ))}
        </div>
      )}
    </div>
  );
}
