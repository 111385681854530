import { Button, Divider, Input, Tab, Tabs, Collapse, IconButton } from "@mui/material";
import { useContext, useState } from "react";
import ContextEditor from "./ContextEditor";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { createContext } from "../../requests";
import TextCompletionPrompting from "./TextCompletionPrompting";
import MapContext from "../../contexts/MapContext";
import CloseIcon from '@mui/icons-material/Close';
import FunctionCallingPrompting from "./FunctionCallingPrompting";
import defaultContexts from "./contextConfigs";
import CopySelectionButton from "../utils/CopySelectionButton";

export default function PromptingPanel({
  selectionType,
  selectionArgs,
}: {
  selectionType: string;
  selectionArgs: any;
}) {
  const [currentTab, setCurrentTab] = useState(0);
  async function getContext() {}
  const {setPromptingSelection} = useContext(MapContext);
  const [showContextEditor, setShowContextEditor] = useState(false);
  const snackbar = useSnackbar();
  const [contextConfigs, setContextConfigs] = useState<any[]>([defaultContexts[0]]);
  const [currentContext, setCurrentContext] = useState<string | null>(null);
  const [showContext, setShowContext] = useState(false);
  async function loadContext() {
    try {
      const response = await createContext(
        {
          selection_type: selectionType,
          ...selectionArgs,
        },
        contextConfigs
      );
      setCurrentContext(response);
      return response;
      snackbar.showSnackbar("Context created successfully", "success");
    } catch (error) {
      snackbar.showSnackbar("Error creating context", "error");
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  async function getGPTResponse() {}

  return (
    <div
    className="clusterPanel"
      style={{
        zIndex:5,
        maxHeight: "calc(100% - 40px)",
        height: "calc(100% - 40px)",
        overflowY: "auto",
        padding: "20px",
      }}
    >
      
       <h3>Selection "{selectionType}"<CopySelectionButton selectionConfig={{
        selection_type:selectionType,
        ...selectionArgs
       }}/></h3> 
      
      <IconButton style={{
        position:'absolute',
        top:'10px',
        left:'10px',
      }} onClick={()=>{
        setPromptingSelection(null);
      }}>
      <CloseIcon/>
      </IconButton>
      <Button
        variant="contained"
        onClick={() => setShowContextEditor(!showContextEditor)}
      >
        {showContextEditor ? "Hide Editor" : "Show Editor"}
      </Button>

      <div>
      <Collapse in={showContextEditor}>
        <ContextEditor
          initialContexts={contextConfigs}
          onUpdateContexts={setContextConfigs}
        />
      </Collapse>
      </div>
      <div className="contextSelection" style={{ margin: "20px 0px 20px 0px" }}>
        <Button
          disabled={contextConfigs.length == 0}
          variant="contained"
          color="primary"
          onClick={loadContext}
        >
          Load Context
        </Button>
        <Button
          disabled={!currentContext}
          variant="outlined"
          color="primary"
          onClick={()=>{
            setShowContext(!showContext)
          }}
        >
          Show Context
        </Button>
        <Divider style={{margin:"10px 0px 10px 0px"}}></Divider>
        {showContext && currentContext  && (
          <div
            style={{
              position:'relative',
              backgroundColor: "lightgrey",
              padding:'10px',
              fontSize:'12px',
              width: "350px",
              height: "200px",
              maxHeight: "200px",
              textAlign: "left",
              overflowY: "auto",
              whiteSpace: "pre-wrap",
            }}
          >
            {currentContext}
            <Button 
            size="small"

            style={{
              position:'absolute',
              top:'10px',
              right:'20px',
              backgroundColor:'white',
              zIndex:10000
            }}
              onClick={
                ()=>{
                  navigator.clipboard.writeText(currentContext as string);
                  snackbar.showSnackbar("copied to clipboard", "success");
                }
              }
              >Copy</Button>
          </div>
        )}
      </div>

      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Text Generation" onClick={getContext} />
        <Tab label="Function Calling" onClick={getGPTResponse} />
        <Tab label="Image Generation" onClick={getGPTResponse} />
      </Tabs>

      <div
        className="promptingEditor"
        style={{
          paddingTop: "10px",
        }}
      >
        {currentTab === 0 && (
          <TextCompletionPrompting selection={
            {
              selection_type: selectionType,
              ...selectionArgs
            }
          } contexts={contextConfigs
          }
          ></TextCompletionPrompting>
        )}
        {currentTab === 1 && (
          <FunctionCallingPrompting selection={
            {
              selection_type: selectionType,
              ...selectionArgs
            }
          } contexts={contextConfigs
          }/>
        )}
        
      </div>
    </div>
  );
}
