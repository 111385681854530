import _ from "lodash";
import React, { createContext, useEffect, useState } from "react";
import IBaseLayer from "../interfaces/baseLayer";
import IClusterDetails from "../interfaces/clusterDetails";
import IClusterSearchResultMeta from "../interfaces/clusterSearchResultMeta";
import IMapPosition from "../interfaces/mapPosition";
import IPaperDetailsWithCoordinates from "../interfaces/paperDetails";
import IPaperMeta from "../interfaces/paperMeta";
import IViewport from "../interfaces/viewport";
import { LayerProps } from "react-map-gl";
import IGeojsonFeature from "../interfaces/geojsonFeature";
import IPromptingSelection from "../interfaces/promptingSelection";
import { ILayer, ISourceJSON } from "../interfaces/mapConfig";
import IAuthorDetails from "../interfaces/authorDetails";
import IAuthorMeta from "../interfaces/authorMeta";
import IJournalMeta from "../interfaces/journalMeta";

export type HoveredPaperType =
  | "search"
  | "author_paper"
  | "author_citation"
  | "author_reference"
  | "paper_citation"
  | "paper_reference"
  | "paper_similar"
  | "cluster";
export interface IHoveredPaper {
  id: number;
  geometry?: IGeojsonFeature;
  meta?: {
    authors?: IAuthorMeta[];
    year: number;
    citationcount: number;
    journal?:IJournalMeta;
    tldr?:{
      model:string;
      text:string;
    };
  } | null;
  title?: string; // Optional, only required if the source is "list"
  source: "list" | "map"; // Indicate the source of the hover event
  type?: HoveredPaperType; //used for identifying which landmark to use
}
export interface IHighlightLayer {
  source: any | null;
  layer: LayerProps;
}

export interface IHoveredCluster {
  cluster_id: number;
  label?: string;
  source: "list" | "map" | "label";
  geometry?: IGeojsonFeature;
}

export interface ISourceConfig {
  type: string;
  data?: any;
  url?: string;
  tiles?: string[];
  [key: string]: any;
}

//TODO add geometrical details (e.g. lng lat or outline)

interface MapContextType {
  mapName: string;
  darkmode: boolean;
  removeClusterLayers: () => void;
  removeAuthorLayers: () => void;
  removePaperLayers: () => void;
  setDarkmode: (darkmode: boolean) => void;
  sidebarOpen: boolean;
  promptingSelection: null | IPromptingSelection;
  viewport: IViewport;
  targetCenter: IMapPosition | null;
  zoom: number;
  selectedCorpusId: number | null;
  selectedCluster: ISelectedCluster | null;
  hoveredPaper: IHoveredPaper | null;
  hoveredCluster: IHoveredCluster | null;
  hoveredPaperDetails: any;
  selectedPaperDetails: IPaperDetailsWithCoordinates | null;
  searchPaperResults: IPaperMeta[] | null;
  searchClusterResults: IClusterSearchResultMeta[] | null;
  searchQuery: string | null;
  selectedClusterDetails: IClusterDetails | null;
  selectedAuthorId: number | null;
  selectedAuthorDetails: IAuthorDetails | null;
  setSelectedAuthorDetails: (details: IAuthorDetails | null) => void;
  setSelectedAuthorId: (id: number | null) => void;
  setSearchClusterResults: (results: IClusterSearchResultMeta[] | null) => void;
  setSelectedClusterDetails: (details: IClusterDetails | null) => void;
  setMapName: (name: string) => void;
  setSelectedCluster: (cluster: ISelectedCluster | null) => void;
  setSidebarOpen: (open: boolean) => void;
  setPromptingSelection: (config: null | IPromptingSelection) => void;
  setViewport: (viewport: IViewport) => void;
  setTargetCenter: (viewport: IMapPosition | null) => void;
  setZoom: (zoom: number) => void;
  setSelectedCorpusId: (id: number | null) => void;
  setHoveredPaper: (hoveredPaper: IHoveredPaper | null) => void;
  setHoveredCluster: (hoveredCluster: IHoveredCluster | null) => void;
  setHoveredPaperDetails: (details: any) => void;
  setSelectedPaperDetails: (
    details: IPaperDetailsWithCoordinates | null
  ) => void;
  setSearchQuery: (query: string | null) => void;
  setSearchPaperResults: (results: IPaperMeta[] | null) => void;
  sourceJSON: ISourceJSON;
  setSourceJSON: (sourceJSON: ISourceJSON) => void;
  addLayer: (
    layerConfig: ILayer,
    sourceConfig: any | null,
    beforeId?: string
  ) => void;
  removeLayer: (id: string) => void;
  upsertLayer: (
    id: string,
    layerConfig: ILayer,
    sourceConfig?: any | null,
    beforeId?: string
  ) => void;
}

// Create the context with default values
const MapContext = createContext<MapContextType>({
  mapName: "",
  removeClusterLayers: () => {},
  removeAuthorLayers: () => {},
  removePaperLayers: () => {},
  selectedAuthorId: null,
  setSelectedAuthorId: () => {},
  selectedAuthorDetails: null,
  setSelectedAuthorDetails: () => {},
  darkmode: false,
  setDarkmode: () => {},
  selectedClusterDetails: null,
  searchClusterResults: null,
  setSearchClusterResults: () => {},
  setSelectedClusterDetails: () => {},
  selectedCluster: null,
  viewport: { ne: { lng: 0, lat: 0 }, sw: { lng: 0, lat: 0 } },
  targetCenter: null,
  zoom: 0,
  selectedCorpusId: null,
  hoveredPaper: null,
  hoveredCluster: null,
  hoveredPaperDetails: null,
  selectedPaperDetails: null,
  sidebarOpen: false,
  promptingSelection: null,
  searchQuery: null,
  searchPaperResults: null,
  setSelectedCluster: () => {},
  setMapName: () => {},
  setSidebarOpen: () => {},
  setPromptingSelection: () => {},
  setViewport: () => {},
  setTargetCenter: () => {},
  setZoom: () => {},
  setSelectedCorpusId: () => {},
  setHoveredPaper: () => {},
  setHoveredCluster: () => {},
  setHoveredPaperDetails: () => {},
  setSelectedPaperDetails: () => {},
  setSearchQuery: () => {},
  setSearchPaperResults: () => {},
  sourceJSON: {
    layers: [],
    sources: {},
    glyphs: "",
    version: 0,
  },
  setSourceJSON: () => {},
  addLayer: () => {},
  removeLayer: () => {},
  upsertLayer: () => {},
});

export interface ISelectedCluster {
  cluster_id: number;
  bounds?: number[] | null;
  geometry?: IGeojsonFeature;
}

export default MapContext;

export const MapProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [darkmode, setDarkmode] = useState(false);
  const [selectedAuthorId, setSelectedAuthorId] = useState<number | null>(null);
  const [selectedAuthorDetails, setSelectedAuthorDetails] =
    useState<IAuthorDetails | null>(null);
  const [viewport, setViewport] = useState<IViewport>({
    ne: { lng: 0, lat: 0 },
    sw: { lng: 0, lat: 0 },
  });
  const [targetCenter, setTargetCenter] = useState<IMapPosition | null>(null);
  const [zoom, setZoom] = useState(0);
  const [selectedClusterDetails, setSelectedClusterDetails] =
    useState<IClusterDetails | null>(null);
  const [searchClusterResults, setSearchClusterResults] = useState<
    IClusterSearchResultMeta[] | null
  >(null);
  const [selectedCorpusId, setSelectedCorpusId] = useState<number | null>(null);
  const [selectedCluster, setSelectedCluster] =
    useState<ISelectedCluster | null>(null);
  const [selectedPaperDetails, setSelectedPaperDetails] =
    useState<IPaperDetailsWithCoordinates | null>(null);
  const [hoveredPaper, setHoveredPaper] = useState<IHoveredPaper | null>(null);
  const [hoveredCluster, setHoveredCluster] = useState<IHoveredCluster | null>(
    null
  );
  const [hoveredPaperDetails, setHoveredPaperDetails] = useState(null);
  const debouncedSetViewport = _.debounce(setViewport, 200);
  const debouncedSetZoom = _.debounce(setZoom, 200);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [promptingSelection, setPromptingSelection] =
    useState<null | IPromptingSelection>(null);
  const [mapName, setMapName] = useState("");
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [searchPaperResults, setSearchPaperResults] = useState<
    IPaperMeta[] | null
  >(null);
  const [sourceJSON, setSourceJSON] = useState<ISourceJSON>({
    layers: [],
    sources: {},
    glyphs: "",
    version: 1,
  });

  useEffect(() => {
    //TODO handle states -> e.g clear search if corpus is selected or author is selected
    if (!selectedAuthorId) {
      removeAuthorLayers();
      setSelectedAuthorDetails(null);
    }
    if (!selectedCorpusId) {
      setSelectedPaperDetails(null);
      removePaperLayers();
    }
    if (!selectedCluster) {
      removeClusterLayers();
    }
  }, [selectedCorpusId, selectedAuthorId]);

  useEffect(() => {
    if (selectedCluster) {
      setSelectedAuthorId(null);
      setSelectedCorpusId(null);
    }
  }, [selectedCluster]);

  useEffect(() => {
    if (selectedAuthorId) {
      setSelectedCluster(null);
      setSelectedCorpusId(null);
    }
  }, [selectedAuthorId]);

  useEffect(() => {
    if (selectedCorpusId) {
      setSelectedAuthorId(null);
      setSelectedCluster(null);
    }
  }, [selectedCorpusId]);
  const findLayerIndexById = (layers: ILayer[], beforeId: string): number => {
    return layers.findIndex((layer) => layer.id.startsWith(beforeId));
  };

  const addLayer = (
    layerConfig: ILayer,
    sourceConfig: ISourceConfig | null,
    beforeId?: string
  ) => {
    setSourceJSON((prev: ISourceJSON) => {
      const newLayers: ILayer[] = [...prev.layers];
      const index = beforeId
        ? findLayerIndexById(prev.layers as any, beforeId)
        : -1;
      if (index !== -1) {
        newLayers.splice(index, 0, layerConfig);
      } else {
        newLayers.push(layerConfig);
      }

      const newSources = { ...prev.sources };
      if (sourceConfig) {
        newSources[layerConfig.source] = sourceConfig;
      }

      return { ...prev, layers: newLayers, sources: newSources };
    });
  };

  const removeAuthorLayers = () => {
    removeLayer("author_papers");
    removeLayer("author_citations");
    removeLayer("heatmap_author_papers");
    removeLayer("heatmap_author_citations");
    removeLayer("author_references");
    removeLayer("heatmap_author_references");
  };

  const removePaperLayers = () => {
    removeLayer("heatmap_paper_citations");
    removeLayer("heatmap_paper_references");
    removeLayer("heatmap_paper_neighbors");
    removeLayer("paper_citations");
    removeLayer("paper_references");
    removeLayer("paper_neighbors");
  };

  const removeClusterLayers = () => {
    removeLayer("selected_cluster");
  };

  const removeLayer = (id: string) => {
    setSourceJSON((prev: ISourceJSON) => {
      const newLayers = prev.layers.filter((layer) => layer.id !== id);

      const newSources = { ...prev.sources };
      const layer = prev.layers.find((layer) => layer.id === id);
      if (layer && newSources[layer.source]) {
        delete newSources[layer.source];
      }

      return { ...prev, layers: newLayers, sources: newSources };
    });
  };

  const upsertLayer = (
    id: string,
    newLayerConfig: ILayer,
    newSourceConfig?: ISourceConfig,
    beforeId?: string
  ) => {
    setSourceJSON((prev: ISourceJSON) => {
      const layerIndex = findLayerIndexById(prev.layers, id);
      const newLayers = [...prev.layers];
      const newSources = { ...prev.sources };

      if (layerIndex === -1) {
        // Layer does not exist, insert it
        const index = beforeId
          ? findLayerIndexById(prev.layers as any, beforeId)
          : -1;
        if (index !== -1) {
          newLayers.splice(index, 0, newLayerConfig);
        } else {
          newLayers.push(newLayerConfig);
        }
      } else {
        // Layer exists, update it
        newLayers[layerIndex] = { ...newLayers[layerIndex], ...newLayerConfig };

        if (newLayerConfig.id in newSources) {
          // Remove existing (custom) source for the given layer if it exists
          delete newSources[newLayerConfig.id];
        }
      }

      if (newSourceConfig) {
        // Add a new source if it exists
        newSources[newLayerConfig.source] = {
          ...newSources[newLayerConfig.source],
          ...newSourceConfig,
        };
      }

      return { ...prev, layers: newLayers, sources: newSources };
    });
  };

  return (
    <MapContext.Provider
      value={{
        removeClusterLayers,
        removeAuthorLayers,
        removePaperLayers,
        selectedAuthorId,
        setSelectedAuthorId,
        selectedAuthorDetails,
        setSelectedAuthorDetails,
        darkmode,
        setDarkmode,
        searchClusterResults,
        setSearchClusterResults,
        selectedClusterDetails,
        setSelectedClusterDetails,
        selectedCluster,
        setSelectedCluster,
        searchPaperResults,
        setSearchPaperResults,
        searchQuery,
        setSearchQuery,
        mapName,
        setMapName,
        viewport,
        targetCenter,
        sidebarOpen,
        setSidebarOpen,
        promptingSelection,
        setPromptingSelection,
        zoom,
        selectedCorpusId,
        hoveredPaper,
        setHoveredPaper,
        hoveredCluster,
        setHoveredCluster,
        hoveredPaperDetails,
        setHoveredPaperDetails,
        selectedPaperDetails,
        setViewport: debouncedSetViewport,
        setTargetCenter,
        setZoom: debouncedSetZoom,
        setSelectedCorpusId,
        setSelectedPaperDetails,
        sourceJSON,
        setSourceJSON,
        addLayer,
        removeLayer,
        upsertLayer,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
