import { useState, useEffect, useRef, useContext } from "react";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  IconButton,
  Popper,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import MapContext from "../contexts/MapContext";
import { fetchAutocompleteSuggestions } from "../requests";

// Custom styled Popper for modifying the dropdown dialog style
const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-paper": {
    backgroundColor: "white",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid lightgrey", // Border for autocomplete options
  },
});

const Search = styled("div")(({ open }:{ open:boolean}) => ({
  position: "absolute",
  top:  "15px",
  left: "15px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: open ? "24px 24px 0 0" : "24px", // Adjust border radius based on open state
  padding: "0px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "350px !important",
  zIndex: 3,
  border: open ? "1px solid lightgrey" : "1px solid white"
}));

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  fontSize: "1.5rem",
}));

export const SearchButton = ({
  initialValue,
  loading,
  onSubmit,
  onClear,
  disabled,
}: {
  initialValue?: string;
  loading: boolean;
  onSubmit: (value: string) => void;
  onClear: () => void;
  disabled?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(initialValue || "");
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState<any>([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { mapName, setSelectedAuthorId,setSelectedCorpusId } = useContext(MapContext);

  // State to control whether the autocomplete is open
  const [open, setOpen] = useState(false);

  // Fetch suggestions when inputValue changes, with debounce
  useEffect(() => {
    if (inputValue.length === 0) {
      const historyQuerys = [
        "history1 item1",
        "history2 items 2",
        "history3 the third ",
      ];

      setOptions(
        historyQuerys.map((query) => ({
          name: query,
          type: "History",
        }))
      );
    }

    const handler = setTimeout(() => {
      const fetchSuggestions = async () => {
        setSuggestionsLoading(true);
        try {
          const data = await fetchAutocompleteSuggestions(mapName, inputValue);
          const authors = data.authors
            .map((author: any) => ({
              ...author,
              type: "Author",
            }))
            .slice(0, 3);
          const papers = data.papers
            .map((paper: any) => ({
              ...paper,
              type: "Paper",
            }))
            .slice(0, 3);
          setOptions([...authors, ...papers]);
        } catch (error) {
          console.error(error);
        } finally {
          setSuggestionsLoading(false);
        }
      };
      fetchSuggestions();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, mapName]);

  const handleSubmit = () => {
    onSubmit(inputValue);
  };

  const handleReset = () => {
    setInputValue("");
    setValue(null);
    onClear();
  };

  return (
    <Search open={open && options.length > 0}>
      <Autocomplete
        disableClearable
        style={{ width: "350px" }}
        freeSolo
        options={options}
        filterOptions={(x) => x} // Override the default filtering behavior to return all options
        groupBy={(option: any) => option.type}
        getOptionLabel={(option: any) => option.name || option.title || ""}
        inputValue={inputValue}
        value={value}
        onKeyDown={(event) => {
            if (event.key === "Enter") {
                console.log("hier",inputValue);
              handleSubmit(); // Submit on Enter key press
            }
          }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          setOpen(newInputValue.length > 0); // Control the open state of autocomplete
          console.log("input change");
        }}
        onChange={(event, newValue: any) => {
          //setValue(newValue);
          setInputValue(newValue);
         
          setOpen(false);
          inputRef.current?.blur(); // Remove focus when an option is selecte
        }}
        loading={suggestionsLoading}
        PopperComponent={(props) => (
          <StyledPopper {...props} placement="bottom-start" />
        )}
        PaperComponent={(props) =>
          options.length > 0 ? <Paper {...props} /> : <div></div>
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <>
                  {suggestionsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {inputValue.length > 0 && (
                    <IconButton onClick={handleReset} size="small">
                      <StyledClearIcon />
                    </IconButton>
                  )}
                  <Button
                    onClick={handleSubmit}
                    disabled={inputValue.length === 0 || disabled}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    disableElevation
                    style={{ outline: "none" }}
                  >
                    <StyledSearchIcon />
                  </Button>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              flexGrow: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none", // Remove the border completely
                },
                "&:hover fieldset": {
                  border: "none", // No border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "none", // No border on focus
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          if (option.type === "Author") {
            return (
              <li
                {...props}
                key={option.authorid}
                onClick={() => {
                  setOpen(false);

                  setSelectedAuthorId(option.authorid);
                  inputRef.current?.blur(); // Remove focus when an option is selecte

                }}
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <PersonOutlineIcon
                  style={{ color: "#70757a", fontSize: "18px" }}
                />{" "}
                <span>
                  {option.name} ({option.papercount} papers)
                </span>
              </li>
            );
          } else if (option.type === "Paper") {
            return (
              <li
                key={option.id}
                {...props}
                onClick={() => {
                    setOpen(false);
                    setSelectedCorpusId(option.id);
                    inputRef.current?.blur(); // Remove focus when an option is selecte

                }}
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <ArticleOutlinedIcon
                  style={{ color: "#70757a", fontSize: "18px" }}
                />
                <span>{option.title}</span>
              </li>
            );
          } else if (option.type === "History") {
            return (
              <li
                {...props}
                key={option.name}
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <ScheduleIcon style={{ color: "#70757a", fontSize: "18px" }} />
                <span>{option.name}</span>
              </li>
            );
          } else {
            return null;
          }
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
    </Search>
  );
};
