import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const StyledDiv = styled.div<{
  headingColor?: string;
  paragraphFontSize?: string;
  paragraphLineHeight?: string;
  paragraphColor?: string;
  listPaddingLeft?: string;
  listMarginTop?: string;
  listStyleType?: string;
  listItemMarginBottom?: string;
  listItemLineHeight?: string;
  strongColor?: string;
  emColor?: string;
}>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.headingColor || "#000000"};
  }

  p {
    margin: 0px;
    font-size: ${(props) => props.paragraphFontSize || "12px"};
    line-height: ${(props) => props.paragraphLineHeight || "1.6"};
    color: ${(props) => props.paragraphColor || "#000000"};
  }

  ul,
  ol {
    padding-left: ${(props) => props.listPaddingLeft || "15px"} !important;
    margin: 0px !important;
    margin-top: ${(props) => props.listMarginTop || "10px"} !important;
    list-style-type: ${(props) => props.listStyleType || "disc"};
  }

  li {
    margin-bottom: ${(props) => props.listItemMarginBottom || "10px"};
    line-height: ${(props) => props.listItemLineHeight || "1.6"};
  }

  strong {
    font-weight: bold;
    color: ${(props) => props.strongColor || "#000000"};
  }

  em {
    font-style: italic;
    color: ${(props) => props.emColor || "#000000"};
  }
`;

const MarkdownEditor = ({
  content,
  headingColor,
  paragraphFontSize,
  paragraphLineHeight,
  paragraphColor,
  listPaddingLeft,
  listMarginTop,
  listStyleType,
  listItemMarginBottom,
  listItemLineHeight,
  strongColor,
  emColor,
}: {
  content: string;
  headingColor?: string;
  paragraphFontSize?: string;
  paragraphLineHeight?: string;
  paragraphColor?: string;
  listPaddingLeft?: string;
  listMarginTop?: string;
  listStyleType?: string;
  listItemMarginBottom?: string;
  listItemLineHeight?: string;
  strongColor?: string;
  emColor?: string;
}) => (
  <StyledDiv
    headingColor={headingColor}
    paragraphFontSize={paragraphFontSize}
    paragraphLineHeight={paragraphLineHeight}
    paragraphColor={paragraphColor}
    listPaddingLeft={listPaddingLeft}
    listMarginTop={listMarginTop}
    listStyleType={listStyleType}
    listItemMarginBottom={listItemMarginBottom}
    listItemLineHeight={listItemLineHeight}
    strongColor={strongColor}
    emColor={emColor}
  >
    <ReactMarkdown>{content}</ReactMarkdown>
  </StyledDiv>
);

export default MarkdownEditor;
