
export interface Sampling {
    method: string;
    num_bins: number;
    k_per_grid: number;
  }
  
export interface SampledTitlesContext {
    method: 'sampled_titles';
    sampling: Sampling;
  }
  
export interface TfContext {
    method: 'tf';
    n: number;
    ngram_range: [number, number];
    min_df: number;
    max_df: number;
  }
  
export interface TopTitlesContext {
    method: 'top_titles';
    column: string;
    k: number;
  }
  
export type Context = SampledTitlesContext | TfContext | TopTitlesContext;
  
const defaultContexts: Context[] = [
  {
    method: 'sampled_titles',
    sampling: {
      method: 'grid',
      num_bins: 10,
      k_per_grid: 1,
    },
  },
  {
    method: 'tf',
    n: 100,
    ngram_range: [2, 2],
    min_df: 10,
    max_df: 0.5,
  },
  {
    method: 'top_titles',
    column: 'citationcount',
    k: 100,
  },
];

export default defaultContexts;