import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { useSnackbar } from "../../contexts/SnackbarContext";

export default function CopySelectionButton({
  selectionConfig,
}: {
  selectionConfig: any;
}) {
  const snackbar = useSnackbar();
  return (
    <IconButton
    style={{backgroundColor:'white'}}
      onClick={() => {
        //copy to clipboard
        navigator.clipboard.writeText(JSON.stringify(selectionConfig));
        snackbar.showSnackbar("copied to clipboard", "success");
      }}
    >
      <ContentCopyIcon />
    </IconButton>
  );
}
