import "./ClusterPreviewBatch.scss";
import { IClusterMetaSmall } from "../../interfaces/clusterMeta";
import { useContext } from "react";
import MapContext from "../../contexts/MapContext";
import { computeBoundingBox } from "../../geometryUtils";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
export default function ClusterPreviewBatch({
  clusterMeta,
  relation
}: {
  clusterMeta: IClusterMetaSmall;
  relation?: "parent" | "child";
}) {
  const { setSelectedCluster, setHoveredCluster, setSelectedClusterDetails } = useContext(MapContext);

  const onClick = (e: any) => {
    let bounds = null;
    if (clusterMeta.bounding_box) {
      bounds = computeBoundingBox(clusterMeta.bounding_box.coordinates);
    }
    setSelectedClusterDetails(null);
    setSelectedCluster({
      cluster_id: clusterMeta.cluster_id,
      bounds: bounds,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className="clusterPreviewBatch"
      onClick={onClick}
      onMouseOver={() => {
        setHoveredCluster({
          cluster_id: clusterMeta.cluster_id,
          geometry: clusterMeta.geometry,
          source: "list",
        });
      }}
      onMouseLeave={() => {
        setHoveredCluster(null);
      }}
    >
      {relation==="child" && <ArrowDownwardIcon/>}
      {relation==="parent" && <ArrowUpwardIcon/>}
      {clusterMeta.label}
    </div>
  );
}
